// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'http://winit-engage.uat.winzard.io/api/',
  // baseUrl: 'http://corals.dev.winzard.io/api/',
  // baseUrl: 'http://staging.winzard.io:3000/api/',
  // baseUrl: 'http://ec2-3-7-65-188.ap-south-1.compute.amazonaws.com:3000/api/',
  production: true,
  // baseUrl:"http://localhost:3000/api/",    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
