import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, retry } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoalManagementService {
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getGoalData(): Observable<any> {
    let path = this.baseUrl + "getGoalData";
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }

 


  getGoalsList(body): Observable<any> {
    let path = this.baseUrl + "goal/getGoalsList";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }


  createGoals(body): Observable<any> {
    let path = this.baseUrl + "goal/getInsertGoal";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getUpdateGoalStatus(body): Observable<any> {
    let path = this.baseUrl + "goal/getUpdateGoalStatus";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  updateGoal(body): Observable<any> {
    let path = this.baseUrl + "goal/getInsertGoal";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getUserList(): Observable<any> {
    let path = this.baseUrl + "goal/getJuniorEmps";
    return this.http.post<any>(path, '').pipe(catchError(this.handleError));
  }
  getReportingList(params): Observable<any> {
    let path = this.baseUrl + "goal/getJuniorEmps";
    return this.http.post<any>(path, params).pipe(catchError(this.handleError));
  }
  getReportingListByUser(params): Observable<any> {
    let path = this.baseUrl + "v3.0/users/getJuniorEmps";
    return this.http.post<any>(path, params).pipe(catchError(this.handleError));
  }

  createWorkPlan(params): Observable<any> {
    let path = this.baseUrl + "goal/getInsertWorkplan";
    return this.http.post<any>(path, params).pipe(catchError(this.handleError));
  }

  createWorkPlanComment(params): Observable<any> {
    let path = this.baseUrl + "goal/getInsertComments";
    return this.http.post<any>(path, params).pipe(catchError(this.handleError));
  }

  getUpdateWorkplanStatus(body): Observable<any> {
    let path = this.baseUrl + "goal/getUpdateWorkplanStatus";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getSupervisorInterventionAlert(body): Observable<any> {
    let path = this.baseUrl + "goal/getSupervisorInterventionAlert";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getRatingSubgoalsWorkplansAlert(body): Observable<any> {
    let path = this.baseUrl + "goal/getRatingSubgoalsWorkplansAlert";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getUnitheadEmployees(body): Observable<any> {
    let path = this.baseUrl + "goal/getUnitheadEmployees";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  checkEditableForGoal(body): Observable<any> {
    let path = this.baseUrl + "goal/checkEditableForGoal";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  checkEditableForWP(body): Observable<any> {
    let path = this.baseUrl + "goal/checkEditableForWP";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
