import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, retry } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../shared/components/confirm-dialog/confirm-dialog.component";
import { JwtHelperService } from "@auth0/angular-jwt";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private baseUrl = environment.baseUrl;
  public userSignUpInfo = { email: "", otp: "" };
  public loginInfo = { isSurvey: '', isFyData: '', moduleData: [] }
  constructor(private http: HttpClient, private dialog: MatDialog) { }

  openConfirmDialog(msg, type, autoClose?, callback?, callbackcancel?): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      panelClass: "confirm-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        msg: msg,
        type: type,
        autoClose: autoClose ? autoClose : ''
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('----------->result', result);

      if (result && callback) {
        callback();
      }
      if (result === false && callbackcancel) {
        callbackcancel();
      }
      if (result === undefined && autoClose) {
        autoClose();
      }
    });
  }

  public getSession(): Promise<boolean> {
    const session = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      if (session) {
        return resolve(true);
      } else {
        return reject(false);
      }
    });
  }

  public decodeToken() {
    const session = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(session);
    return decodedToken;
  }

  validateLoginUser(body): Observable<any> {
    let path = this.baseUrl + "authenticate";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getForgotPassword(body): Observable<any> {
    let path = this.baseUrl + "getForgotPassword";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getVerifyOTP(body): Observable<any> {
    let path = this.baseUrl + "getVerifyOTP";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getResetPassword(body): Observable<any> {
    let path = this.baseUrl + "getResetPassword";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getCheckEmailSignup(body): Observable<any> {
    let path = this.baseUrl + "getCheckEmailSignup";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getUpdateUser(body): Observable<any> {
    let path = this.baseUrl + "getUpdateUser";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getUpdateCompanyInfo(body): Observable<any> {
    let path = this.baseUrl + "getUpdateCompanyInfo";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  // importEmployees(body): Observable<any> {
  //   let path = this.baseUrl + "importEmployees";
  //   return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  // }
  importEmployees(body): Observable<any> {
    let path = this.baseUrl + "importEmployees";
    return this.http.post<any>(path, body);
  }

  importUsers(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/importEmployees";
    return this.http.post<any>(path, body);
  }

  CaterogyValueImportUser(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/find-categoryValues";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getValuesByType(body): Observable<any> {
    let path = this.baseUrl + "getValuesByType";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getValuesByTypeUsers(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/getValuesByType";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getUsersAndRoles(): Observable<any> {
    let path = this.baseUrl + 'v2.0/employees/getUsersAndRoles'
    return this.http.get<any>(path)
      .pipe(
        catchError(this.handleError)
      );
  }
  getUsersAndRolesSurvey(): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/getUsersAndRoles'
    return this.http.get<any>(path)
      .pipe(
        catchError(this.handleError)
      );
  }
  UpdateUsersAndRoles(body): Observable<any> {
    let path = this.baseUrl + "v2.0/employees/updateUsersAndRoles";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  UpdateUsersAndRolesSurvey(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/updateHoc";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getRolesList(bulkUpdate: boolean): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/rolesList?bulkUpdate=' + bulkUpdate + ''
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  bulkUpdateRoles(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/bulkUpdateRoles";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  bulkUpdateReportingManager(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/bulkUpdateReportingManager";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  bulkDeleteEmployees(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/bulkDeleteEmployees";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  bulkUpdateCategories(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/bulkUpdateCategories";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getEmployeesByRole(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/getEmployeesByRole";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  deleteEmployeesByRole(body): Observable<any> {
    let path = this.baseUrl + "v3.0/users/delRoleForEmployee";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }


  deleteCategoryFromEmp(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/deleteCategoryFromEmp";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getModuleList(): Observable<any> {
    let path = this.baseUrl + 'v3.0/company/getModuleList';
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
