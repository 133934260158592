import { RouterGuard } from "./core/router-guard";
import { LoginGuard } from "./core/login-guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CanDeactivateGuard } from '../app/core/can-deactivate-guard';

const routes: Routes = [

  {
    path: "",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: "",
    redirectTo: "/winzard",
    pathMatch: "full",
  },
  {

    path: "winzard",
    loadChildren: () => import("./hr/hr.module").then((m) => m.HrModule),
    canActivate: [RouterGuard],

  },
  {
    path: "onboard",
    loadChildren: () =>
      import("./onboard/onboard.module").then((m) => m.OnboardModule),
    canActivate: [RouterGuard],
  },
  {
    path: "safety-survey/:safetyId/:EmpId",
    loadChildren: () =>
      import("./survey-questions/survey-questions.module").then((m) => m.SurveyQuestionsModule),
  },
  {
    path: "engagement-survey/:engagementId/:EmpId",
    loadChildren: () =>
      import("./engagement-survey-questionary/engagement-survey-questionary.module").then((m) => m.EngagementSurveyQuestionaryModule),
  },
  { path: 'my-win', loadChildren: () => import('./hr/my-win/my-win.module').then(m => m.MyWinModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
