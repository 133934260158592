import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HrService {
  private baseUrl = environment.baseUrl
  constructor(
    private http: HttpClient
  ) { }

  registerComplaint(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/complaints/registerComplaint'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }



  getGradeList(): Observable<any> {
    let path = this.baseUrl + 'getGrades'
    var response = this.http.post<any>(path, '');
    return response;

  }
  getEmployees(body): Observable<any> {
    let path = this.baseUrl + 'getEmployees'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getUsers(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/getEmployees'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getEmployeeList(body): Observable<any> {
    let path = this.baseUrl + 'talent/getTalentGradeEmployees'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getValuesByTypeEmployee(body, status: boolean): Observable<any> {
    let path = this.baseUrl + 'getValuesByType?status=' + status + ''
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getValuesByTypeUser(body, status: boolean): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/getValuesByType?status=' + status + ''
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getValuesByType(body): Observable<any> {
    let path = this.baseUrl + 'getValuesByType'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getValuesByTypeUsers(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/getValuesByType'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  createEmployee(body): Observable<any> {
    let path = this.baseUrl + 'createEmployee'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  createUsers(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/createEmployee'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  updateEmployee(body): Observable<any> {
    let path = this.baseUrl + 'updateEmployee'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  updateUser(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/users/updateEmployee'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getDeleteEmployee(body): Observable<any> {
    let path = this.baseUrl + 'getDeleteEmployee'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getSaveTalentManagerIntiate(body): Observable<any> {
    let path = this.baseUrl + 'talent/getSaveTalentManager'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDueTalentSurveyEmployees(body): Observable<any> {
    let path = this.baseUrl + 'talent/getDueTalentSurveyEmployees'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getUpdateTalentSurveyStatus(body): Observable<any> {
    let path = this.baseUrl + 'talent/getUpdateTalentSurveyStatus'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getTalentSurveys(body): Observable<any> {
    let path = this.baseUrl + 'talent/getTalentSurveys'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getRemindTalentDueEmployees(body): Observable<any> {
    let path = this.baseUrl + 'talent/getRemindTalentDueEmployees'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getCheckActiveStatusReviews(body): Observable<any> {
    let path = this.baseUrl + 'talent/getCheckActiveStatusReviews'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }


  getEditTalentSurveyById(body): Observable<any> {
    let path = this.baseUrl + 'talent/getEditTalentSurveyById'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }


  getSurveyList(body): Observable<any> {
    let path = this.baseUrl + 'getSurveyList'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getSurveybyType(body): Observable<any> {
    let path = this.baseUrl + 'getSurveybyType'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getEngagementSurveys(body): Observable<any> {
    let path = this.baseUrl + 'getEngagementSurveys'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getInsertSurvey(body): Observable<any> {
    let path = this.baseUrl + 'getInsertSurvey'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getDueSurveyEmployees(body): Observable<any> {
    let path = this.baseUrl + 'getDueSurveyEmployees'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getUpdateSurveyStatus(body): Observable<any> {
    let path = this.baseUrl + 'getUpdateSurveyStatus'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  getEditSurvey(body): Observable<any> {
    let path = this.baseUrl + 'getEditSurvey'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveWES(body): Observable<any> {
    let path = this.baseUrl + 'saveWES1'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCurrentFincalDeatils(): Observable<any> {
    let path = this.baseUrl + 'getCurrentFY';
    var response = this.http.post<any>(path, '')
    return response;
  }

  getFincalYearDetailsList(): Observable<any> {
    let path = this.baseUrl + 'getFincalYearDetails';
    var response = this.http.post<any>(path, '')
    return response;
  }
  getallGradeList(): Observable<any> {
    let path = this.baseUrl + 'v2.0/grades/all'
    return this.http.get<any>(path)
      .pipe(
        catchError(this.handleError)
      );
  }
  getCompetenciesList(): Observable<any> {
    let path = this.baseUrl + 'v2.0/competencies/all'
    var response = this.http.get<any>(path)
    return response;
  }
  getEmployeeListByGrades(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/employees/all?status=true'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  createPeopleAssessments(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessments/create'
    var response = this.http.post<any>(path, body);
    return response;
    // let url = this.globalService.environmentCheck() + 'ManageTractorOwners/AddTractorOwner';
    // const headers = new HttpHeaders().set("Content-Type", "application/json");
    // let formValue = JSON.stringify(managetractorowner);
    // var response = this.httpClient.post(url, formValue, { headers });
    // return response;
  }
  updatePeopleAssessments(body, assessmentId): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessments/edit-assessment/' + assessmentId;
    var response = this.http.put<any>(path, body);
    return response;
  }
  getPeopleAssessmentsList(pageNo: number, perPage: number, sortObj: string): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessments/all?pageNo=' + pageNo + '&perPage=' + perPage + '&sort=' + sortObj;
    var response = this.http.get<any>(path)
    return response;
  }
  getPeopleAssessmentReviewsListByStatus(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessment-reviews/by-status-and-assessment-id';
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  sendRemainderNotification(assessmentId: string): Observable<any> {

    let path = this.baseUrl + 'v2.0/people-assessments/send-remainder-notification/' + assessmentId;
    return this.http.get<any>(path)
      .pipe(
        catchError(this.handleError)
      );
  }
  changeStatus(status: string, assessmentId: string): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessments/change-status-by-assessment-id/' + status + '/' + assessmentId;
    var response = this.http.put<any>(path, '');
    return response;
  }
  getPeopleAssessmentsById(assessmentId: string): Observable<any> {

    let path = this.baseUrl + 'v2.0/people-assessments/by-id/' + assessmentId;
    return this.http.get<any>(path)
      .pipe(
        catchError(this.handleError)
      );
  }
  getIsActiveAssessment(): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessments/is-active-assessment';
    var response = this.http.get<any>(path)
    return response;
  }
  createSafetySurvey(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/survey/create'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  updateSafetySurvey(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/survey/update'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  checkActiveSafetySurvey(type:String,purchaseId: string): Observable<any> {
    let path = this.baseUrl + 'v3.0/survey/checkActiveSurvey/'+ type + '/' + purchaseId;
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  dueEmpListSafetySurvey(surveyId: string): Observable<any> {
    let path = this.baseUrl + 'v3.0/surveyEmp/dueEmpList/' + surveyId;
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }

  getSafteySurveyList(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/survey/getList'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  generateInsights(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/surveyInsights/generateInsights'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  insightAccess(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/survey/insightAccess'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  resendSafetySurveyLink(body): Observable<any> {
    let path = this.baseUrl + 'v3.0/survey/resendSurveyLink'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  sendReminderSafteySurvey(body): Observable<any> {
    let path = this.baseUrl + 'survey/sendReminder'
    return this.http.post<any>(path, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  createAnnouncement(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/announcements/create'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  updateAnnouncement(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/announcements/update'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  updateStatusAnnouncement(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/announcements/updateStatus'
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  announcementList(perPage,pageNo): Observable<any> {
    let path = this.baseUrl + 'v2.0/announcements/getList?perPage=' + perPage + '&pageNo=' + pageNo 
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  visionGetList(status): Observable<any> {
    let path = this.baseUrl + 'v2.0/visionBoard/getList?status=' + status
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  deleteVision(id: string): Observable<any> {
    let path = this.baseUrl + 'v2.0/visionBoard/delete/' + id;
    return this.http.delete<any>(path).pipe(catchError(this.handleError));
  }
  createVison(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/visionBoard/create'
    return this.http.post<any>(path,body).pipe(catchError(this.handleError));
  }
  updateVison(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/visionBoard/update'
    return this.http.post<any>(path,body).pipe(catchError(this.handleError));
  }
  visionStatusChange(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/visionBoard/updateStatus'
    return this.http.post<any>(path,body).pipe(catchError(this.handleError));
  }
  
  announcementgetListByUser(): Observable<any> {
    let path = this.baseUrl + 'v2.0/announcements/getListByUser'
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  microLearninggetListByUser(): Observable<any> {
    let path = this.baseUrl + 'v2.0/dashboard/getMlListByUser'
    return this.http.get<any>(path).pipe(catchError(this.handleError));
  }
  updateStatusByUserRead(body): Observable<any>{
    let path = this.baseUrl + 'v2.0/dashboard/updateStatus'
    return this.http.post<any>(path,body).pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage)
  }

}
