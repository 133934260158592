import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../authentication/auth.service";
import { CommonModuleServiceService } from "../../../shared/services/v3/common-module-service.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  userInfo: any;
  showFiller: boolean = false
  navBar: Array<any> = [];
  moduleArray:any;

  constructor(private Auth: AuthService,private common:CommonModuleServiceService) {
    this.userInfo = Auth.decodeToken();
  }

  ngOnInit() {
    this.sideBarList();
  }
 
  async sideBarList() {
    this.moduleArray = await this.common.getModulesList();
    console.log('Modules', this.moduleArray);
    
    if (!this.userInfo.isSurvey) {
      this.moduleList('Organization', './organization', 'fa-dashboard', 'dashboard');
     
      if(this.moduleArray && this.moduleArray.moduleData && this.moduleArray.moduleData.length >0){
        if (this.moduleArray.moduleData.findIndex(x =>  x.moduleId.moduleId === 'GA') > -1) {
          this.moduleList('Goal Achiever', './goalAchiever', 'fa-bullseye', 'users')
        }
        if (this.moduleArray.moduleData.findIndex(x =>  x.moduleId.moduleId === 'TM') > -1) {
          this.moduleList('Talent Manager', './talentMangement', 'fa-users', 'talentManager')
        }
        if (this.moduleArray.moduleData.findIndex(x =>  x.moduleId.moduleId === 'PA') > -1) {
          this.moduleList('People Assessment', './peopleAssessment', 'fa-eye', 'peopleassesment')
        }
      }
      this.moduleList('Announcements', './announcements', 'fa-bullhorn', 'announcement');
      
    }
    else {
      this.moduleList('Organization', './survey-organization', 'fa-dashboard', 'surveyDashboard');
      if(this.moduleArray && this.moduleArray.moduleData && this.moduleArray.moduleData.length >0){
        if (this.moduleArray.moduleData.findIndex(x =>  x.moduleId.moduleId === 'WS') > -1) {
          this.moduleList('Workforce Wellbeing', './workforce-wellbeing', 'fa-list-alt', 'safety')
        }
        if (this.moduleArray.moduleData.findIndex(x =>  x.moduleId.moduleId === 'WE') > -1) {
          this.moduleList('Workplace Engagement', './workplace-engagement', 'fa-list-alt', 'engagement')
        }
      }
      
    }
  }

  moduleList(name, route, iconName, key?) {
    this.navBar.push({
      name: name,
      route: route,
      iconName: iconName,
      key: key,
    })
    return this.navBar
  }
  dropdownTrigger(ev, item) {
    item.isOpen = !item.isOpen;
  }
}
