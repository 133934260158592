import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if(data.autoClose != ''){
        setTimeout(() => {
          this.closeDialog();
        }, 3000);
      }
     }

  closeDialog(): void {
    this.dialogRef.close(true);
  }
  closeDl(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    
  }
  onConfirm(){
    this.dialogRef.close(true);
  }

}
