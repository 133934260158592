import { MatSort } from '@angular/material';
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { AuthService } from "../../../authentication/auth.service";
import * as _ from "lodash";
import { InsightsService } from "src/app/insights/insights.service";
import { Observable } from "rxjs";
import { startWith, map, filter } from "rxjs/operators";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { GoalManagementService } from "src/app/goal-management/goal-management.service";
import { MatTableDataSource, MatSidenav, MatDialog } from '@angular/material';
import * as moment from "moment";
import { HrService } from 'src/app/hr/hr.service';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from '../../shared.service';
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
@Component({
  selector: "app-shared-goal-management",
  templateUrl: "./goal-management.component.html",
  styleUrls: ["./goal-management.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden", display: 'none' })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class GoalManagementComponent implements OnInit {
  @ViewChild("drawer", { static: true }) public sidenav: MatSidenav;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  userListLoaded: boolean = false;
  loader: boolean = false;
  usersList = [];
  ratings: Array<any> = [1, 2, 3, 4, 5];
  selectedRow: any;
  selectedIndex: number;
  fYear: any = "";
  viewBy = "all";
  rating = "all";
  sideNavLoader: boolean = false;
  formSubmmitted = false;
  public loaded: any;
  editFormGroup: FormGroup;
  workplanFormGroup: FormGroup;
  commentFormGroup: FormGroup;
  fincalYearDetails: any;
  dataSource = new MatTableDataSource();
  selectedRowIndex;
  currentGoalOFWorkPlan;
  currentGoalWorkPlan;
  editWorkPlanRow;
  editWorkPlanRowIndex = 0;
  autoCompleteList = [];
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  talentID: any = "";
  checkActiveTm: string = "";
  achievementFullValue: any;
  isUnitGoalPage: boolean = false;
  fincalYearList = [];
  frequency: any;
  maxEndDate: any;
  minStartDate: any;
  empName:any


  monthList: Array<any> = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  monthToQuaterly: any = {};
  monthlyList: any = [];
  quaterlyList: any = [];



  columnsToDisplay = [
    "sNo",
    "goalTitle",
    "kpi",
    "target",
    "weightage",
    "startdate",
    "enddate",
    "achievement",
    "status",
  ];
  columnNames = {
    sNo: "S.No",
    goalTitle: "Goal",
    kpi: "KPI",
    target: "Target",
    weightage: "Weightage/ Importance Factor",
    startdate: "Start Date",
    enddate: "End Date",
    achievement: "Achievement",
    status: "Status",
  };
  expandedElement: any;
  statusList: Array<any> = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "Inactive",
    },
    {
      id: "3",
      name: "Completed",
    },
  ];

  wpMinDate: any;
  wpMaxDate: any;
  importanceFactor = ["1", "2", "3", "4", "5"];
  addCommentsList = [];
  enableSupervisorIntervention = false;
  enableReadWorkplan = false;
  userInfo: any;
  isWorkplanEnabled = false;
  isAddWorkplan = false;
  showAddComment = false;

  @ViewChild("stepper", { static: false }) stepper;

  constructor(
    private _service: HrService,
    public shared: SharedService,
    private service: InsightsService,
    private goalService: GoalManagementService,
    private auth: AuthService,
    private fb: FormBuilder,
    public router: Router,
    private dialog: MatDialog,
    private googleAnalyticsService:GoogleAnalyticsService
  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        let activeUrl = event['url'].split('/');
        if (activeUrl.includes('unitGoals')) {
          this.isUnitGoalPage = true;
        } else {
          this.isUnitGoalPage = false;
        }
        console.log(event);
      });
  }



  ngOnInit() {
    this.googleAnalyticsService.sendCurrentActiveLink(window.location.href);
    this.createEditForm();
    this.createWorkplanForm();
    this.createCommentForm();
    this.getFincalYearDetails();
    if (!this.isUnitGoalPage) {
      this.service.getValueTypes({ type: "emps" }).subscribe((resp) => {
        this.autoCompleteList = resp.data;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      });
    } else {
      let filters = {
        fincalId: ""
      };
      this.goalService.getUnitheadEmployees(filters).subscribe((resp) => {
        this.autoCompleteList = resp.data;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      });
    }

    this.userInfo = this.auth.decodeToken();
    // this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }

  private _filter(value: string): string[] {
    if (typeof value == "string") {
      const filterValue = value.toLowerCase();
      return this.autoCompleteList.filter(
        (option) => option.fullname.toLowerCase().indexOf(filterValue) === 0 ||
          option.empId.toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      return [];
    }
  }

  displayFn(user: any): string {
    return user && user.fullname ? user.fullname : "";
  }

  saveSearch(flag?) {
    console.log(flag);

    this.getGoalsList();
    if (flag) {
      this.stepper.next();
    }
  }
  resetSearch() {
    this.myControl.reset();

    // this.getGoalsList();
  }
  optionSelected(e) {
    this.myControl.setValue(e.option.value);
  }

  getFincalYearDetails() {
    let parmas ={
      // isDisplay:true
    }
    this.shared.getFinancialYearList(parmas).subscribe((res) => {
      if (res && res.success) {
        res.data.map((date,index)=>{
         
          if(date.status == 'Next' && date.isDisplay == false){
            res.data.splice(index,1);
          }
          
        });
        this.fincalYearList = res.data;
        this.fincalYearDetails = res.data[0];
        this.fYear = this.fincalYearDetails._id;
        this.frequency = this.fincalYearDetails.frequency;
        console.log('current Quaterly Monthly', this.frequency);

        // if (!this.isTeamWin) {
        //   this.selectedRow = this.userInfo;
        //   this.getGoalsList();
        // }
        // else {
        //   this.getUsersList();
        // }
        this.doChange();


      }
    });
  }

  doChange(event?) {
    if (event) {
      this.fincalYearDetails = _.find(this.fincalYearList, ['_id', this.fYear]);
    }
    this.setMaxDate();
    this.setMinDate();
    this.createsubGoalsLabel();
    // this.getSupervisorInterventionAlert();
    // this.getSubgoalsWorkplaneAlert();


  }
  setMaxDate() {
    const date = new Date();
    const endmonth = String(this.fincalYearDetails.endmonth).substring(0, 3);
    date.setFullYear(this.fincalYearDetails.secondyear);
    date.setMonth(this.monthList.indexOf(endmonth) + 1);
    date.setDate(0);
    this.maxEndDate = date;
  }
  setMinDate() {
    const date = new Date();
    let startMonth = String(this.fincalYearDetails.startmonth).substring(0, 3);
    date.setFullYear(this.fincalYearDetails.firstyear);
    startMonth = _.startCase(_.toLower(startMonth));
    date.setMonth(this.monthList.indexOf(startMonth));
    date.setDate(1);
    this.minStartDate = date;
  }


  createsubGoalsLabel() {

    var start = new Date(this.fincalYearDetails.firstyear, this.monthList.indexOf(String(this.fincalYearDetails.startmonth).substring(0, 3)), 1);
    var end = new Date(this.fincalYearDetails.secondyear, this.monthList.indexOf(String(this.fincalYearDetails.endmonth).substring(0, 3)), 2);

    console.log('date', start, end);

    var startDate = moment(start);
    var endDate = moment(end);

    var result = [];

    while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
      result.push(startDate.format('MMMM-YYYY'));
      startDate.add(1, 'month');
    }
    console.log('final', result, result.length, this.fincalYearDetails);

    let data = []
    result.forEach((e) => {
      console.log(e);
      let startMonth = new Date(e).getMonth();
      console.log(startMonth);
      data.push(startMonth)
    })

    let fIndex;
    let sIndex;
    let TIndex
    let FIndex;


    for (let index = 0; index < data.length; index++) {
      // console.log('-------->',data[index]);
      if (index < 3) {
        console.log('0', data[index]);
        fIndex = 0
      }
      else if (index >= 3 && index < 6) {
        console.log('1', data[index]);
        sIndex = 1
      }
      else if (index >= 6 && index < 9) {
        console.log('2', data[index]);
        TIndex = 2
      }
      else if (index >= 9 && index <= 11) {
        console.log('3', data[index]);
        FIndex = 3
      }
    }

    this.monthToQuaterly = {
      0: { name: data[0], index: fIndex },
      1: { name: data[1], index: fIndex },
      2: { name: data[2], index: fIndex },
      3: { name: data[3], index: sIndex },
      4: { name: data[4], index: sIndex },
      5: { name: data[5], index: sIndex },
      6: { name: data[6], index: TIndex },
      7: { name: data[7], index: TIndex },
      8: { name: data[8], index: TIndex },
      9: { name: data[9], index: FIndex },
      10: { name: data[10], index: FIndex },
      11: { name: data[11], index: FIndex },

    }
    console.log('djdjjd', this.monthToQuaterly);




    if (this.fincalYearDetails.frequency.toLowerCase() === "monthly") {
      this.monthlyList = result;
    }

    else {
      this.quaterlyList = [
        result[0] + ' - ' + result[2],
        result[3] + ' - ' + result[5],
        result[6] + ' - ' + result[8],
        result[9] + ' - ' + result[11],

      ]
    }
    console.log('Result', this.monthlyList, this.quaterlyList);

  }



  getGoalsList() {
    const emp = _.find(this.autoCompleteList, [
      "fullname",
      this.myControl.value,
    ]);
    console.log(emp,"11111");
    
    if (emp != undefined) {
      this.loader = true;
      const params = {
        empId: emp._id
      };
      params["filters"] = {
        fincalId: this.fYear,
        viewrateplan: this.viewBy,
        showassesment: this.rating,
      };

      this.goalService.getGoalsList(params).subscribe(
        (res) => {
          this.loader = false;
          if (res && res.success && res.data) {
            console.log(res.data.length,res,"rrr");
            if(res.data.length == 0){
              console.log('msg Print', res.empDetails.fullname);

              this.auth.openConfirmDialog(
                `Goal data doesn’t exist for ${res.empDetails.fullname}`,
                "error",
                // "autoClose"
              );
              
            }
            this.empName=res.empDetails.fullname
            res.data.forEach((e) => {
              e.isExpanded = 'false';
            })
            this.dataSource.data = res.data;
            setTimeout(() => {
              this.dataSource.sort = this.sort;
            }, 100);
          }
        },
        (err) => {
          console.log("HTTP Error", err);
        }
      );
    }
  }
  // showSidebar
  editRow(event, index) {
    if (event) {
      event.stopPropagation();
    }
    this.isAddWorkplan = false;
    this.isWorkplanEnabled = false;
    // this.editWorkPlanRow = false;
    const element: any = this.dataSource.data[index];
    this.selectedRowIndex = index;
    element.goalName = element.goalTitle;
    this.editFormGroup.patchValue(element);
    const formArray = this.editFormGroup.get("subGoals") as FormArray;
    formArray.clear();
    element.subgoalsinfo.map((item) => {
      formArray.push(
        this.fb.group({
          subgoalvalue: null,
          subgoalAchievement: null,
          userRating: null,
          supervisorRating: null,
          ...item,
        })
      );
    });
    // this.onDateChange(null);
    this.sidenav.open();
  }

  closeSidenav() {
    this.sidenav.close();
    // this.selectedRowIndex = null;

  }

  createEditForm() {
    this.editFormGroup = this.fb.group({
      goalName: ["", Validators.required],
      kpi: ["", Validators.required],
      target: ["", Validators.required],
      weightage: ["", Validators.required],
      startdate: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      enddate: ["", Validators.required],
      achievement: [null, Validators.required],
      fincalId: null,
      assignedEmpId: null,
      _id: "",
      subGoals: this.fb.array([]),
    });
  }



  createWorkplanForm() {
    this.workplanFormGroup = this.fb.group({
      workplanTitle: ["", Validators.required],
      description: ["", Validators.required],
      descriptionIcon: false,
      supervisorComments: [""],
      challengesAndSupport: ["", Validators.required],
      kpi: ["", Validators.required],
      target: ["", Validators.required],
      importanceFactor: ["", Validators.required],
      startdate: new FormControl(null, [Validators.required]),
      enddate: ["", Validators.required],
      achievement: [null, Validators.required],
      fincalId: null,
      assignedEmpId: null,
      goalId: "",
      supervisorRating: null,
      userRating: null,
      _id: null,
    });
  }

  openAchievment(myDialog, value) {
    console.log('dfjdbnj', value);

    this.achievementFullValue = value;
    console.log('BSHHSHSHHHHHHHHHH------------', this.achievementFullValue);

    this.dialog.open(myDialog);
  }

  async prevRowWP() {
    if (this.editWorkPlanRowIndex != 0) {
      this.editWorkPlan(this.editWorkPlanRowIndex - 1);
    } else {
      if (this.selectedRowIndex != 0) {

        for (let index = this.selectedRowIndex - 1; index >= 0; index--) {
          console.log('else block exceute', this.selectedRowIndex);
          this.selectedRowIndex = index;
          const element: any = this.dataSource.data[index];
          console.log('element', element);

          if (element && element.workplaninfo && element.workplaninfo.length > 0) {
            console.log('Goals Has Workplans', element, index);
            this.selectedRowIndex = index;
            this.editWorkPlan(element.workplaninfo.length - 1, element);
            break;
          }
          else {
            console.log('continue', index);

            continue;
          }
        }

        // this.selectedRowIndex = this.selectedRowIndex - 1;

        // this.minusRowEvent(null, this.selectedRowIndex);
      }
    }
  }

  async nextRowWP() {
    console.log(this.currentGoalWorkPlan, this.editWorkPlanRowIndex,this.selectedRowIndex,this.dataSource.data)

    if (this.currentGoalWorkPlan.workplaninfo.length > this.editWorkPlanRowIndex + 1) {
      console.log("opennnn")
      this.editWorkPlan(this.editWorkPlanRowIndex + 1);
    } else {
      if (this.dataSource.data.length > this.selectedRowIndex + 1) {
        console.log("btwwwww", this.dataSource.data)
        for (let index = this.selectedRowIndex + 1; index < this.dataSource.data.length; index++) {

          this.selectedRowIndex = index;
          const element: any = this.dataSource.data[index];
          console.log(element, "weeee")
          if (element && element.workplaninfo && element.workplaninfo.length > 0) {
            console.log('Goals Has Workplans', element, index);
            this.selectedRowIndex = index;
            this.editWorkPlan(0, element);
            break;
          }
          else {
            console.log('Goal has no workPlans', index);

            if (index == this.dataSource.data.length - 1) {
              console.log('lastIndex');
              // this.checkActiveWorkPlan();

            }
            continue;
          }
        }
      } else {

      }
    }
  }

  createCommentForm() {
    this.commentFormGroup = this.fb.group({
      commentDescription: null,
      commentsupervisorDescription: null,
      likeordislike: false,
      supervisorIntervention: false,
      done: false,
      status: true,
      _id: null,
    });
  }

  editWorkPlan(index, row?) {
    this.sidenav.open();
    this.isWorkplanEnabled = true;
    if (row) {
      this.currentGoalWorkPlan = row

      this.wpMinDate = row.startdate;
      this.wpMaxDate = row.enddate;
    }
    this.currentGoalOFWorkPlan = this.currentGoalWorkPlan.workplaninfo[index];
    this.editWorkPlanRow = this.currentGoalWorkPlan.workplaninfo[index];
    this.editWorkPlanRowIndex = index;
    this.isAddWorkplan = false;
    this.showAddComment = false;
    this.workplanFormGroup.reset();
    this.workplanFormGroup.patchValue(this.editWorkPlanRow);
  }


  clearCommentsForm() {
    this.commentFormGroup.patchValue({
      commentDescription: null,
      commentsupervisorDescription: null,
      likeordislike: false,
      supervisorIntervention: false,
      done: false,
      status: true,
      _id: null,
    });
  }

  addComment() {
    this.clearCommentsForm();
    this.showAddComment = true;
    if (this.currentGoalOFWorkPlan.comments.length > 0) {
      this.commentFormGroup.patchValue(this.currentGoalOFWorkPlan.comments[0]);
    }
  }

  addNewComment() {
    this.addCommentsList.unshift({
      assignedEmpId: this.editWorkPlanRow.assignedEmpId,
      createdEmpId: this.currentGoalWorkPlan.createdEmpId,
      workplanId: this.editWorkPlanRow._id,
      goalId: this.currentGoalWorkPlan._id,
      commentDescription: null,
      commentsupervisorDescription: null,
      likeordislike: false,
      supervisorIntervention: false,
      done: false,
      status: true,
    });
  }

  onWPRatingClick(star, key) {
    if (this.workplanFormGroup.get(key).value == star) {
      this.workplanFormGroup.get(key).setValue(0);
    } else {
      this.workplanFormGroup.get(key).setValue(star);
    }
  }

  onRatingClick(star, form, key) {
    if (form.get(key).value == star) {
      form.get(key).setValue(0);
    } else {
      form.get(key).setValue(star);
    }
  }




  getCommentDate(commentDate, isHead?) {
    if (isHead) {
      if (commentDate) {
        return moment(commentDate).format("Do MMM YYYY");
      }
    }
    if (commentDate) {
      return moment(commentDate).format("hh:mm A | MMM Do, YYYY");
    }
  }

  splitWord(word, n?) {
    if (!n) {
      n = 2;
    }
    if (word) {
      return String(word).substring(0, n);
    }
    return word;
  }
  splitYear(word) {
    if (word) {
      return String(word).substring(2, 4);
    }
    return word;
  }
  splitWords(word) {

    if (word) {

      var result = word.match(/[A-Z][a-z]+/g);
      let final = String(result[0]).substring(0, 3) + ' - ' + String(result[1]).substring(0, 3);
      return final;

    }
    return word


  }

  checkFlags(data) {
    for (let i = 0; i < data.length; i++) {
      const goal = data[i].workplaninfo;
      for (let j = 0; j < goal.length; j++) {
        if (goal[j].supervisorIntervention) {
          this.enableSupervisorIntervention = true;
        }
        if (goal[j].readworkplan) {
          this.enableReadWorkplan = true;
        }
        if (this.enableReadWorkplan && this.enableSupervisorIntervention) {
          break;
        }
      }
    }
  }
  cancelEditGoal() {
    let formchanged: boolean = false;
    this.editFormGroup.valueChanges.subscribe((r) => {
      formchanged = true;
    });
    if (formchanged) {
      const cb = () => {
        this.sidenav.close();
      };
      this.auth.openConfirmDialog(
        "Are you sure you want cancel?",
        "alert",
        false,
        cb
      );
    } else {
      this.sidenav.close();
    }
    this.selectedRowIndex = null;
  }

  toggleRow(row) {
    if (this.expandedElement && this.expandedElement.id == row.id) {
      this.expandedElement = null;
      this.selectedRowIndex = null;
      return this.expandedElement;
    } else {
      this.expandedElement = row;
    }
    return row;
  }

  cancelEditWorkPlan() {
    let formchanged: boolean = this.editFormGroup.dirty;
    if (this.isWorkplanEnabled) {
      formchanged = this.workplanFormGroup.dirty;
    }
    if (formchanged) {
      const cb = () => {
        this.sidenav.close();
      };
      this.auth.openConfirmDialog(
        "Are you sure you want cancel?",
        "alert",
        false,
        cb
      );
    } else {
      this.sidenav.close();
    }
  }

  nextRow() {
    if (this.dataSource.data.length > this.selectedRowIndex + 1) {
      this.editRow(null, this.selectedRowIndex + 1);
    }
  }
  prevRow() {
    if (this.selectedRowIndex != 0) {
      this.editRow(null, this.selectedRowIndex - 1);
    }
  }
  isIndexTrue(element, index) {
    console.log('index', index);

    if (element.isExpanded = !element.isExpanded) {
      console.log('dma');

      this.selectedRowIndex = null;
    }
    else {
      console.log('done');

      this.selectedRowIndex = index;

    }

  }

}
