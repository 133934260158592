import { Injectable } from '@angular/core';
import { CategoryManagementService } from '../../../settings/category-management.service';
import { AuthService } from "src/app/authentication/auth.service";
@Injectable({
  providedIn: 'root'
})
export class CommonModuleServiceService {

  constructor(private Caterogy_Serive: CategoryManagementService, private Auth: AuthService) { }

  async getCaterogyList() {
    let params = {
      isActive: true
    }
    let result = await new Promise((resolve) => {
      this.Caterogy_Serive.getCategoryList(params).subscribe(
        (res) => {
          if (res && res.success && res.data) {
            resolve(res.data)
          }
        },
        (err) => {
          console.log(err);

        })
    })

    return result;
  }

  async getCaterogyValueById(params) {
    let result: any = await new Promise((resolve) => {
      this.Caterogy_Serive.getCategoryValuesList(params).subscribe(
        (res) => {
          if (res && res.success && res.data) {
            resolve(res.data)
          }
        },
        (err) => {
          console.log(err);

        })
    })
    result.sort(function (a, b) {
      if (a.valueName < b.valueName) { return -1; }
      if (a.valueName > b.valueName) { return 1; }
      return 0;
    })

    return result;
  }

  async getRolesList(bulkUpdate) {

    let result = await new Promise((resolve) => {
      this.Auth.getRolesList(bulkUpdate).subscribe(
        (res) => {
          if (res && res.success && res.data) {
            resolve(res.data)
          }
        },
        (err) => {
          console.log(err);

        })
    })

    return result;
  }

  async getModulesList() {

    let result = await new Promise((resolve) => {
      this.Auth.getModuleList().subscribe(
        (res) => {
          if (res && res.success && res.data) {
            resolve(res.data)
          }
        },
        (err) => {
          console.log(err);

        })
    })

    return result;
  }
}
