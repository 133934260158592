import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import {Observer} from 'rxjs/Observer';

import { ConfirmDialogComponent } from "../shared/components/confirm-dialog/confirm-dialog.component";
import { tap } from 'rxjs/operators';
import { AuthService } from "src/app/authentication/auth.service";
import { ConfirmationComponent } from '../shared/components/confirmation/confirmation.component';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

export interface ComponentCanDeactivate {
  confirm(): boolean ;
}
@Injectable({ providedIn: "root" })
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  public submittedOnce = false;
  constructor(private dialog: MatDialog,
    private auth: AuthService,private _router: Router, private route :ActivatedRoute) {}
  canDeactivate(
    component: ComponentCanDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,    
  ): boolean {
    let msg = "Entered data will be lost, are you sure you want to continue?";
    
    if (!component.confirm()) {
      const confirmResult = confirm(msg);
      if (confirmResult === true) {
      return true;
      } else {
      return false;
      }
     }else {
      return true;
      }
    }
  
  
    //  this.dialog.open(ConfirmDialogComponent, {
    //     width: "500px",
    //     panelClass: "confirm-dialog",
    //     autoFocus: false,
    //     disableClose: true,
    //     data: {
    //       msg: msg,
    //       type: 'alert',
    //       autoClose:false?false:''
    //     },
    //   }).afterClosed().subscribe((result) => {
    //     if (result === true) {
    //       this.submittedOnce= true;
    //     } else {
    //     return false;
    //     }
    //     return this.submittedOnce;
    //   })
    //   return this.submittedOnce;
    // }
         
  
  
  //     dialogRef.afterClosed().subscribe((result) => {
      
  //     if (result) {
  //       this.submittedOnce = true;
  //       // this._router.navigate([this.route]);      
  //     }
  //     if (result=== undefined) {
  //       this.submittedOnce = false;
  //     }
  //   });
  //    }
  //  return this.submittedOnce;

// public submittedOnce = false;
// constructor(private dialog: MatDialog,
//   private auth: AuthService,private _router: Router, private route :ActivatedRoute) {}
// canDeactivate(
//   component: ComponentCanDeactivate,
//   next: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot,    
//    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//   return this.canILeave(component);
// }
// private canILeave(component) {
//   if (!component.confirm()) {
//     return this.openDialog();
//   } else {
//     return true;
//   }
// }


// public async openDialog(){
//   let msg = "Entered data will be lost, are you sure you want to continue?";
  
//   let result = false;
//   const dialogRef = this.dialog.open(ConfirmationComponent, {
//     width: "500px",
//     panelClass: "confirm-dialog",
//     autoFocus: false,
//     disableClose: true,
//     data: {
//       msg: msg,
//       type: 'alert',
//       autoClose:false?false:''
//     },
//   });
//   await dialogRef.afterClosed().subscribe(res => {
//     console.log('res: ' + res);
//     result = res;
//   });
//   return result;
// }
}