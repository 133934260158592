import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { NgControl, FormControl } from "@angular/forms";

@Directive({
  selector: "[appNumbersOnly]",
})
export class NumbersOnlyDirective {
  public prevVal;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input() maxVal: string;

  @Input() maxEntry: number;
  @Input() formControl: FormControl;

  constructor(private _el: ElementRef, private control: NgControl) {}
  @HostListener("input", ["$event"])
  @HostListener("blur", ["$event"])
  onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    let filterdVal;
    filterdVal = initalValue.replace(/[^0-9]*/g, "");
    // if(initalValue == 0){
    //   console.log('done');
      
    //   filterdVal = initalValue.replace(/[^1-9]*/g, "");
    // }
    // else{
    //   console.log('dndn');
      
    //   filterdVal = initalValue.replace(/[^0-9]*/g, "");
    // }

    
     

    if (parseInt(this.maxVal) >= parseInt(filterdVal)) {
      this.prevVal = filterdVal;
    }

    if (parseInt(this.maxVal) < parseInt(filterdVal)) {
      if (this.prevVal) {
        filterdVal = this.prevVal;
      } else {
        filterdVal = "";
      }
    }

    if (this.maxEntry && filterdVal.toString().length > this.maxEntry) {
      filterdVal = parseInt(filterdVal.toString().substr(0, this.maxEntry));
    }

    if (this.formControl) {
      this.formControl.setValue(filterdVal);
    } else {
      this._el.nativeElement.value = filterdVal;
    }
    this.ngModelChange.emit(filterdVal);
    //this.control.control.patchValue(filterdVal);
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
