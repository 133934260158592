import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatTableDataSource,
  MatPaginator,
  MatSidenav,
  MatSort,
  MatOption,
  MatDialog,
} from "@angular/material";
import { HrService } from "../../../hr/hr.service";
import { MatAccordion } from '@angular/material/expansion';
import { AuthService } from 'src/app/authentication/auth.service';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  selectedObj: any;
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  panelOpenState = false;
  announcementList: any;
  visionNewForm: FormGroup;
  inProgessVisionForm: FormGroup;
  visonContent = new FormControl()
  visionBoardNewList: any = [];
  visionCompletedList: any = []
  taskArray: any[] = [];
  visionNewList: any = [];
  coursesList:any =[
    {
      name:'how to positively influence your team',
      url:'https://courses-winzard.s3.ap-south-1.amazonaws.com/how-to-positively-influence-your-team/index.html#/'
    },
    {
      name:'How to Build a Cohesive Team',
      url:'https://courses-winzard.s3.ap-south-1.amazonaws.com/how-to-build-a-cohesive-team/content/index.html#/'
    },

  ];
  visionInprogressList: any = []
  step = 0;
  constructor(private dialog: MatDialog, private HrService: HrService, private fb: FormBuilder, private auth: AuthService) { }

  ngOnInit() {
    this.getannouncementList();
    this.microLearningList();
    this.visionNewForm = this.fb.group({
      visonData: this.fb.array([]),
    });
    this.inProgessVisionForm = this.fb.group({
      visionData: this.fb.array([])
    });
    this.getVisionData();


    console.log('sksk', this.visionBoardNewList);

  }

  microLearningList(){
    this.HrService.microLearninggetListByUser().subscribe((resp)=>{
      if(resp){
        this.taskArray = resp.data;
      }
    },(err)=>{console.log(err);
    })
  }


  getVisionData() {
    // 'New','In-progress',Completed
    this.HrService.visionGetList('New').subscribe((resp) => {
      if (resp && resp.data) {
        this.visionNewList = resp.data;
        this.patchNewVisionData();
      }

    }, (err) => console.log(err))

    this.HrService.visionGetList('In-progress').subscribe((resp) => {
      if (resp && resp.data) {
        this.visionInprogressList = resp.data;
        this.patchInProgrssVision();
      }

    }, (err) => console.log(err))
    this.HrService.visionGetList('Completed').subscribe((resp) => {
      if (resp && resp.data) {
        this.visionCompletedList = resp.data
      }

    }, (err) => console.log(err))

  }
  statusChangeCompleted(data, value) {
    console.log(data)
    let parmas = {
      _id: data._id,
      status: value
    }
    this.apiStatusChange(parmas);
  }
  statusChange(data, value) {
    console.log(data, value);
    let parmas = {
      _id: data.value._id,
      status: value
    }

    this.apiStatusChange(parmas);
  }
  apiStatusChange(parmas) {
    this.HrService.visionStatusChange(parmas).subscribe((resp) => {
      if (resp) {
        this.auth.openConfirmDialog(resp.msg, "error", "autoClose");
        this.getVisionData();
      }
    })
  }
  getannouncementList() {
    this.HrService.announcementgetListByUser().subscribe((resp) => {
      if (resp && resp.data) {
        console.log(resp.data);
        this.announcementList = resp.data;

      }

    }, (err) => { console.log(err) })
  }
  addNewVision() {
    const formArray = this.visionNewForm.get("visonData") as FormArray;
    formArray.insert(0,this.addControl());
    this.setStep(0);
  }
  patchNewVisionData() {
    const formArray = this.visionNewForm.get("visonData") as FormArray;
    formArray.controls.length = 0;

    for (let i = 0; i < this.visionNewList.length; i++) {
      formArray.push(new FormGroup({
        valueName: new FormControl(this.visionNewList[i].content),
        _id: new FormControl(this.visionNewList[i]._id)
      }))
    }

    console.log(formArray.value);

  }
  patchInProgrssVision() {
    const formArray = this.inProgessVisionForm.get("visionData") as FormArray;
    formArray.controls.length = 0;

    for (let i = 0; i < this.visionInprogressList.length; i++) {
      formArray.push(new FormGroup({
        valueName: new FormControl(this.visionInprogressList[i].content),
        _id: new FormControl(this.visionInprogressList[i]._id)
      }))
    }
  }
  setStep(index: number) {
    this.step = index;
  }
  addControl() {
    return this.fb.group({
      valueName: ["", Validators.required],
      _id: [""]
    });
  }
  openContent(Micro, obj) {
    this.selectedObj = obj;
    this.dialog.open(Micro);
    let parmas = {
      docId: obj._id
    }
    this.read(parmas);
  }

  openAnnouncement(Announcement, obj) {
    console.log(obj);

    this.selectedObj = obj;
    this.dialog.open(Announcement);

    let parmas = {
      docId: obj._id
    }
    this.read(parmas);

  }
  deleteVisonData(data) {
    let id = data.value._id;
    console.log(id);
    this.deletAPI(id)
  }
  deleteVison(data) {
    console.log(data);
    this.deletAPI(data._id)
  }

  deletAPI(id) {
    this.HrService.deleteVision(id).subscribe((resp) => {
      console.log(resp);
      if (resp) {
        this.auth.openConfirmDialog(resp.msg, "error", "autoClose");
        this.getVisionData();
      }

    }, (err) => {
      console.log(err);
    })
  }

  read(parmas) {
    this.HrService.updateStatusByUserRead(parmas).subscribe((resp) => {
      console.log(resp);
      this.getannouncementList();
      this.microLearningList();
    }, (err) => {
      console.log(err);
    })
  }
  func() {
    console.log('dsds');

  }
  otherFunc(data) {
    console.log(data.value);
    let parmas = {
      content: data.value.valueName
    }
    //api

    if (data.value._id) {
      console.log(data.value._id);

      parmas['_id'] = data.value._id;
      this.HrService.updateVison(parmas).subscribe((resp) => {
        console.log(resp);
        if (resp) {
          this.auth.openConfirmDialog(resp.msg, "error", "autoClose");
          this.getVisionData();
        }

      }, (err) => {
        console.log(err);
      })
    }
    else {
      this.HrService.createVison(parmas).subscribe((resp) => {
        console.log(resp);
        if (resp) {
          this.auth.openConfirmDialog(resp.msg, "error", "autoClose");
          this.getVisionData();
        }

      }, (err) => {
        console.log(err);
      })
    }
  }

  detectTextarea(value) {
    console.log(value);

  }
}
