import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FlowDirective, Transfer } from '@flowjs/ngx-flow';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import * as _ from "lodash";
import { AuthService } from '../../authentication/auth.service';
import * as moment from 'moment';
import { HrService } from '../../hr/hr.service';
import { InteractionService } from '../../../app/shared/services/interaction.service';
import { async } from '@angular/core/testing';
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../../app/shared/components/confirm-dialog/confirm-dialog.component";
import { Router,NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from "../../shared/services/google-analytics.service";
@Component({
  selector: 'app-upload-users',
  templateUrl: './upload-users.component.html',
  styleUrls: ['./upload-users.component.scss']
})
export class UploadUsersComponent implements OnInit {

  @ViewChild('flow', { static: false }) flow: FlowDirective;
  public employeesData = [];
  public backEndEmpKeys = ["empid", "name", "gender", "mobile", "email", "dob", "doj", "grade", "designation", "unit", "unithead", "subunit", "subunit2", "location", "branch", "reportingTo", "rmempid"];
  public xlsxMandatoryEmpKeys = ["empid", "name", "gender", "mobile", "email", "dob", "doj", "grade", "designation", "unit", "unithead", "location", "reportingTo"];
  public optionalEmptyKeys = ["subunit2", "branch", "rmempid"];
  public mandatoryKeys = ["empid", "email", "reportingTo"];
  //"grade", "designation", "unit"
  public alphaNumericKeys = ["empid", "name", "unithead", "location", "reportingTo"];
  public originalXlOptionalKeys = ["SubUnitorSubFunction", "SubFunction2", "Branch", "ReportingManager EmployeeName"];
  public originalXlMandatorylKeys = [];
  public xlsxEmpKeys = [];
  public processedEmpObject = [];
  public empUniqueItems = [];
  public invalidEmpList = [];
  public invalidMandatoryEmpList = [];
  public invalidEmpListWithReportingTo = [];
  public invalidEmpEmail: any = [];
  public validEmpList = [];
  public alreadyImportedEmailId = [];
  public uploadEmailIds = []
  public alreadyImportedEmployees = [];
  public uploadingEmpIds = [];
  public xlsxUploadedFileName;
  public uploadInProgress: boolean = false;
  public headOfCompanyFound: boolean = true;
  public employeeEmailFound: boolean = true;
  public hideUploadProgress: boolean = true;
  public InvalidReporting: boolean = false;
  public isFirstUploadValidation:boolean = false;
  public isProccedKey: boolean = false;
  public showSpinner: boolean = true;
  public duplicateHOCfound: boolean = false;
  public duplicateEmployee = []
  public testArray = [];
  public employeeEmailIds = [];
  public shouldRemoveEmpIds = [];
  public allEmployees = [];
  public headOfCompanyEmployee = [];
  public duplicateHeadOfCompany = [];
  public allImportedEmpIds = [];
  public lastIndex: any;
  dateBith: boolean = false;
  duplicateEmailBoolean = false;
  public userInfo:any;
  // public userInfo = JSON.parse(localStorage.getItem("userInfo")).data.user;
  public uploadEmpSections = {
    "uploadArea": { "show": true },
    "errorArea": { "show": false },
    "backendErrorArea": { "show": false },
    "uniqueItemsArea": { "show": false }
  };
  public backendInvalidempList = []
  public copyEmployeesData = [];
  public uniqueGrades = [];
  public uniqueLocations = [];
  public uniqueUnits = [];
  public maxUniqueItems: number;
  public uploadEmpSectionsArr = Object.keys(this.uploadEmpSections);
  public autoUploadSubscription: Subscription;
  public uploadEmpDataFromAddEmpDialog = JSON.parse(localStorage.getItem("employeeUploadFromAddEmpDialog"));
  public originalExcelData = [];
  public newlyUploadedEmployees = [];
  public renamedKeysEmployees = [];
  public currentLoginEmailId:any;
  @Input() from: string;
  @Output() uploadExcelEvent = new EventEmitter<string>();
  @Input() activeUserCreationTab: any;
  constructor(
    private _service: AuthService,
    private router: Router,
    private hrService: HrService,
    private _interactionService: InteractionService,
    private dialog: MatDialog,
    private googleAnalyticsService:GoogleAnalyticsService
  ) { 
  
  }

  ngOnInit() {
    this.userInfo = this._service.decodeToken();
    this.currentLoginEmailId = this.userInfo.email;
    this.googleAnalyticsService.sendCurrentActiveLink(window.location.href);
    if (!this.from) {
      localStorage.removeItem("employeeUploadFromAddEmpDialog");
    }
    let listParams = { "limit": 10000, "page": 1, "status": true, "sort": { "empId": 1 }, "filter": { "ageonnetwork": [], "directby": null, "filterby": null, "gender": [], "grade": [], "location": [], "searchby": null, "unit": [] } }

    this.hrService.getEmployees(listParams).subscribe((result) => {
      console.log(result);
      if (result.success) {
        this.showSpinner = false;
        if (result.data && result.data.Employees && result.data.Employees.length > 0) {
          let finalData = []
          finalData = result.data.Employees;

          console.log('------------->', finalData);


          finalData.forEach((each) => {

            let arr = [];
            each.unitinfo.map((val) => {
              arr.push(val.unitName)
            });
            let units = arr.toString();

            each.data = {

              empid: each.empId,
              rmempid: each.reportinginfo.empId,
              name: each.fullname,
              gender: each.gender,
              mobile: each.phone,
              email: each.email,
              dob: moment(each.dob).format("DD-MM-YYYY"),
              doj: moment(each.dateofjoining).format("DD-MM-YYYY"),
              grade: each.gradeinfo.gradeName,
              designation: each.designation,
              unit: units,
              unithead: each.unithead,
              subunit: each.subunit,
              subunit2: each.subunit2,
              location: each.locationobja.locationName,
              branch: each.branch,
              reportingTo: each.reportinginfo.empId

            }
            this.allEmployees.push(each.data);

          })

          console.log('------------->upload employes', this.allEmployees);
          finalData.forEach((each) => {
            if (each.role == 1) {
              console.log('head', each);
              let arr = [];
              each.unitinfo.map((val) => {
                arr.push(val.unitName)
              });
              let units = arr.toString();
              each.heads = {
                empid: each.empId,
                rmempid: 'Head of Company',
                name: each.fullname,
                gender: each.gender,
                mobile: each.phone,
                email: each.email,
                dob: moment(each.dob).format("DD-MM-YYYY"),
                doj: moment(each.dateofjoining).format("DD-MM-YYYY"),
                grade: each.gradeinfo.gradeName,
                designation: each.designation,
                unit: units,
                unithead: each.unithead,
                subunit: each.subunit,
                subunit2: each.subunit2,
                location: each.locationobja.locationName,
                branch: each.branch,
                reportingTo: 'Head of Company'
              }
              this.headOfCompanyEmployee.push(each.heads);

            }
          })
          // console.log('------------->upload head of employes', this.headOfCompanyEmployee);





        }
        else {
          this.allEmployees = [];
          this.headOfCompanyEmployee = []

        }

      }
    });

    if (this.uploadEmpDataFromAddEmpDialog) {
      this.employeesData = this.uploadEmpDataFromAddEmpDialog.empXlsxData;
      this.xlsxUploadedFileName = this.uploadEmpDataFromAddEmpDialog.fileName;
      //this.validateUploadedFile("uniqueItemsArea");
      console.log('log', this.xlsxUploadedFileName);
      
      this.originalXlMandatorylKeys = this.uploadEmpDataFromAddEmpDialog.originalXlMandatorylKeys;
      this.xlsxEmpKeys = Object.keys(this.employeesData[0]);
      this.resetAllSections();
      this.getValuesByTpes();
      this.uploadEmpSections['uniqueItemsArea']['show'] = true;
    }
    // this._interactionService.employeeMsg$.subscribe(message=>{
    //   console.log('msg-------->', message);
    //   // this.getPersonelInfo();

    //  });

    //  this._interactionService.employee1Msg$.subscribe(msg=>{
    //   console.log('msg-------->', msg);
    //  })
  }
  ngOnChanges() {
    if (this.activeUserCreationTab == "userCreation") {
      this.xlsxUploadedFileName = "";
    }
  }



  onFileChange(ev) {
    this.employeesData = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev;
    this.uploadExcelEvent.emit();
    reader.onload = (event) => {
      let workBook = XLSX.read(reader.result, {
        type: 'binary', cellDates: true, cellNF: false,
        cellText: false
      });
      let jsonPagesArray = [];
      workBook.SheetNames.forEach(function (sheet) {
        jsonPagesArray.push(JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(workBook.Sheets[sheet], { defval: "" }))));
      });
      this.buildEmployeeData(jsonPagesArray[0]);
    }
    reader.readAsBinaryString(file);
  }
  buildEmployeeData(data) {
    let tempObj = Object.values(data);
    let xlKeys = Object.values(tempObj[0]);
    _.map(tempObj, (emp, index) => {
      if (index > 0) {
        let tempEmp = {};
        _.map(Object.values(emp), (em, emIndex) => {
          tempEmp[xlKeys[emIndex]] = em;
        });

        this.employeesData.push(tempEmp);
      }
    });
    this.xlsxEmpKeys = Object.keys(this.employeesData[0]);
    this.originalXlMandatorylKeys = _.remove(Object.keys(this.employeesData[0]), (key) => {
      return _.indexOf(this.originalXlOptionalKeys, key) == -1;
    });

    this.originalExcelData = _.cloneDeep(this.employeesData);
    this.employeesData.map((empData) => {
      this.xlsxEmpKeys.map((ekey, eindex) => {
        this.renameKeys(empData, ekey, this.backEndEmpKeys[eindex]);
      });
    });
    this.renamedKeysEmployees = _.cloneDeep(this.employeesData);

    if (this.from) {
      console.log('if block');
      
      let uploadInfoFromAddEmpDialog = {
        fileName: this.xlsxUploadedFileName,
        empXlsxData: this.employeesData,
        originalXlMandatorylKeys: this.originalXlMandatorylKeys
      };
      localStorage.setItem("employeeUploadFromAddEmpDialog", JSON.stringify(uploadInfoFromAddEmpDialog));
    } else {
      console.log('else');
      localStorage.removeItem("employeeUploadFromAddEmpDialog");
    }

  }

  validateUploadedFile(areaName) {
    this.resetAllSections();
    this.getValuesByTpes();
    this.uploadEmpSections[areaName]['show'] = true;
  }
  getValuesByTpes() {
    this.showSpinner = true;



    this._service.getValuesByType({ filter: false, type: "empids" }).subscribe(res => {
      if (res.success) {
        if (res.data.length > 0) {

          console.log('------->res', res.data);

          this.showSpinner = false;
          this.alreadyImportedEmployees = _.map(res.data, "empId");
          // _.remove(this.employeesData, emp => this.alreadyImportedEmployees.indexOf(emp.empid) > -1);


          this.newlyUploadedEmployees = _.cloneDeep(this.employeesData);

          this.duplicateEmployee = _.cloneDeep(this.employeesData);

          if (this.newlyUploadedEmployees.length > 0) {
            this.processEmployeeId()
            this.processEmployeeObject();
            this.processEmptyEmpList();
          }

        }
      }
    });

  }
  processEmployeeId() {
    this.newlyUploadedEmployees.map((empData) => {
      if (this.uploadingEmpIds.indexOf(empData['empid']) == -1) {

        this.uploadingEmpIds.push((empData['empid']).toString());

        console.log('empIds-------->', this.uploadingEmpIds);


      }
    })


  }
  dateFormatChange(key) {
    if (key.indexOf('T') > -1) {
      key = moment(key).add(2, 'hour').format("DD/MMMM/YYYY");
      key = moment(key).format("MM/DD/YYYY");
      console.log('key If----->UTC Format', key);
    }
    else {
      key = moment(key, 'DD-MM-YYYY').add(2, 'hour').format("DD/MM/YYYY");
      console.log('else key', key);
      key = moment(key, 'DD/MM/YYYY').format('MM-DD-YYYY');
      key = moment(key, 'MM-DD-YYYY').format('MM/DD/YYYY');

    }


    console.log('final---------->', key);
    return key;
  }

  uniqueFilter(key, arr) {
    if (key && arr.indexOf(key) == -1) {
      arr.push(key);
      console.log('----------->', arr);
      arr.sort();
    }
    return arr;
  }

  uniqueUnitFilter(key, arr) {
    let str = key.toString();
    let splitArr = [];
    splitArr = str.split(',');
    splitArr.map((val) => {
      console.log('units', val);
      val = val.toString().trim();
      if (val && arr.indexOf(val) == -1) {
        arr.push(val);
        console.log('----------->', arr);
        arr.sort();
      }
    })


    return arr;
  }

  processEmployeeObject() {


    this.newlyUploadedEmployees.map((empData, index) => {
      console.log('first----------->', empData["empid"], empData['dob']);


      empData['dob'] = this.dateFormatChange(empData['dob']);
      empData['doj'] = this.dateFormatChange(empData['doj']);


      console.log('date', empData['dob'], empData['doj']);

      Object.keys(empData).forEach(key => {


        if (key) {
          // if (empData[key] == "" && _.findIndex(this.invalidEmpList, { "empid": empData["empid"] }) == -1) {
          //   this.invalidEmpList.push(empData);
          //   console.log('Final--------->',this.invalidEmpList);


          // }


          if (this.xlsxMandatoryEmpKeys.indexOf(key) > -1 && empData[key] == "" && _.findIndex(this.invalidEmpList, { "empid": empData["empid"] }) == -1) {

            this.invalidEmpList.push(empData);
            // console.log('5--------->',this.invalidMandatoryEmpList);

          }

        }
      });



      if (empData['email'] == "" || empData['reportingTo'] == "" || empData['empid'] == "") {
        this.removeItems(empData)
        // if (this.shouldRemoveEmpIds.indexOf(empData['empid']) == -1) {
        //   this.shouldRemoveEmpIds.push(empData['empid']);

        // }
      }
      // if (this.uploadingEmpIds.indexOf(empData['empid']) == -1) {

      //   this.uploadingEmpIds.push((empData['empid']).toString());

      //   console.log('empIds-------->', this.uploadingEmpIds);


      // }
      this.uniqueGrades = this.uniqueFilter(empData.grade, this.uniqueGrades);
      this.uniqueLocations = this.uniqueFilter(empData.location, this.uniqueLocations);
      this.uniqueUnits = this.uniqueUnitFilter(empData.unit, this.uniqueUnits);


      this.maxUniqueItems = Math.max(...[this.uniqueGrades.length, this.uniqueLocations.length, this.uniqueUnits.length]);
      if (this.employeeEmailIds.indexOf(empData.email) == -1) {
        this.employeeEmailIds.push(empData.email);
      }
      if (index == this.newlyUploadedEmployees.length - 1) {
        this.lastIndex = index;
        console.log('done', this.lastIndex);

      }
      this.validateFields(empData);


    });

  }
  removeItems(empData) {
    if (this.shouldRemoveEmpIds.indexOf(empData['empid']) == -1) {
      this.shouldRemoveEmpIds.push(empData['empid']);

    }
  }
  validateFields(empData) {


    let copyEmp = _.cloneDeep(empData);


    if (empData) {

      let index = _.findIndex(this.invalidEmpList, { empid: copyEmp.empid });
      let tempIndex = _.findIndex(this.invalidEmpList, { empid: copyEmp.empid });

      Object.keys(copyEmp).forEach(key => {
        if (this.alphaNumericKeys.indexOf(key) > -1) {

          if (!(/^([a-zA-Z0-9 _-]+)$/.test(empData[key])) && empData[key].length > 0) {

            if (index > -1) {
              this.invalidEmpList[index][key] = { valid: false, value: empData[key] };
              console.log('final2-------->', this.invalidEmpList);
              this.removeItems(empData)
            } else {
              copyEmp[key] = { valid: false, value: copyEmp[key] };
              this.invalidEmpList.push(copyEmp);
              console.log('final3-------->', this.invalidEmpList);
              this.removeItems(empData)
            }
          }
        }
      });
      if (copyEmp.mobile && /^([0-9 _-]+)$/.test(copyEmp.mobile) && copyEmp.mobile.toString().length > 5) {
        // copyEmp["mobile"] = { valid: false, value: copyEmp.mobile };
      }
      else {
        if (index > -1) {
          this.invalidEmpList[index]["mobile"] = { valid: false, value: copyEmp.mobile };
          console.log('final5-------->', this.invalidEmpList);
          this.removeItems(empData)
        } else {
          copyEmp["mobile"] = { valid: false, value: copyEmp.mobile };
          this.invalidEmpList.push(copyEmp);
          console.log('final6-------->', this.invalidEmpList);
          this.removeItems(empData)
        }
      }
      if (!(/(.+)@(.+){2,}\.(.+){2,}/.test(copyEmp.email))) {
        if (index > -1) {
          this.invalidEmpList[index]["email"] = { valid: false, value: copyEmp.email };
          console.log('final7-------->', this.invalidEmpList);
          this.removeItems(empData)

        } else {
          copyEmp["email"] = { valid: false, value: copyEmp.email };
          this.invalidEmpList.push(copyEmp);
          console.log('final8-------->', this.invalidEmpList);
          this.removeItems(empData)

        }


      }


      console.log('emp', empData.empid);

      if (this.lastIndex == this.newlyUploadedEmployees.length - 1) {
        // Email Verification
        let empUd = _.map(this.allEmployees, "empid");
        console.log('email1', empUd);

        _.remove(this.duplicateEmployee, emp => empUd.indexOf(emp.empid.toString()) > -1);
        console.log('2emaiil', this.duplicateEmployee);

        let tempArray = [...this.allEmployees, ...this.duplicateEmployee];
        console.log('3email3 ', tempArray);

        const duplicateEmail = _.countBy(tempArray, 'email');
        let duplicateItems = [];
        duplicateItems = _.filter(tempArray, x => duplicateEmail[x.email] > 1);

        if (duplicateItems.length > 0) {
          duplicateItems.forEach((copyEmp) => {
            this.duplicateEmailBoolean = true;
            copyEmp["email"] = { valid: false, value: copyEmp.email };
            this.invalidEmpList.push(copyEmp);
            console.log('final8-------->', this.invalidEmpList);
            this.removeItems(copyEmp)
          });
        }

        // Find Duplicate HOC

        let empId = _.map(this.headOfCompanyEmployee, "empid");
        console.log('1==============empUd', empUd);

        _.remove(this.duplicateEmployee, emp => empId.indexOf(emp.empid.toString()) > -1);
        console.log('2=========log', this.duplicateEmployee);

        let temp = [...this.headOfCompanyEmployee, ...this.duplicateEmployee];
        console.log('3===========all ', temp);

        const HOC = _.countBy(temp, 'reportingTo');
        console.log('realData--------', HOC);

        let duplicateArray = [];

        duplicateArray = _.filter(temp, x => HOC[x.reportingTo == 'Head of Company'] > 1);
        console.log('duplicate Head Of Company', duplicateArray);

        if (duplicateArray.length > 0) {
          duplicateArray.forEach((copyEmp) => {
            this.duplicateHOCfound = true;
            copyEmp["reportingTo"] = { valid: false, value: copyEmp.reportingTo };
            this.invalidEmpList.push(copyEmp);
            console.log('final8-------->', this.invalidEmpList);
            this.removeItems(copyEmp);
          });
        }

      }





      if (copyEmp.dob) {
        let x = new Date();
        let y = new Date(empData.dob);
        console.log('----------->log', x, y);


        if (x < y) {
          copyEmp["dob"] = { valid: false, value: copyEmp.dob };
          this.invalidEmpList.push(copyEmp);
          this.removeItems(empData)
          this.dateBith = true;

          console.log('date Issue======', this.invalidEmpList);

        }

        if (copyEmp['dob'] == 'Invalid date') {
          copyEmp["dob"] = { valid: false, value: copyEmp.dob };

          this.invalidEmpList.push(copyEmp);
          this.removeItems(empData);
          console.log('date Issue--------->', this.invalidEmpList);


        }

      }

      if (copyEmp.doj) {
        if (copyEmp['doj'] == 'Invalid date') {
          copyEmp["doj"] = { valid: false, value: copyEmp.doj };
          this.invalidEmpList.push(copyEmp);
          this.removeItems(empData);
          this.invalidEmpList = _.uniqBy(this.invalidEmpList, 'empid');
          console.log('date Issue--------->', this.invalidEmpList);
        }
      }

      let tempArray = [...this.alreadyImportedEmployees, ...this.uploadingEmpIds];



      console.log('totalArray', tempArray);

      if (tempArray.indexOf(empData.reportingTo.toString()) == -1 && empData.reportingTo !== "Head of Company") {
        console.log('each----------------------------------------', copyEmp);
        // console.log('1 case---------->');

        if (this.shouldRemoveEmpIds.indexOf(empData.empid.toString()) == -1) {
          this.shouldRemoveEmpIds.push(empData.empid);
          console.log('2 case---------->');

        }
        
        if(empData.reportingTo !=  ''){
          console.log('mainContent', tempIndex, empData.reportingTo,'sjsjsjs');
          if (tempIndex > -1) {
            console.log('mainfjk--------------------------------------------------------',);
  
  
            this.invalidEmpList[tempIndex]["reportingTo"] = { valid: false, value: copyEmp.reportingTo };
            this.InvalidReporting = true;
            this.removeItems(empData)
          }
          else {
            console.log('case 3----------------------------------------', copyEmp);
  
            copyEmp["reportingTo"] = { valid: false, value: copyEmp.reportingTo };
            this.invalidEmpList.push(copyEmp);
            this.removeItems(empData);
            this.InvalidReporting = true;
  
            console.log('done----------->', this.invalidEmpList);
  
  
          }
        }
        else{
          this.invalidEmpList.push(empData);
        }
        

      }

      if (this.lastIndex == this.newlyUploadedEmployees.length - 1) {

        this.invalidEmpList = _.uniqBy(this.invalidEmpList, 'empid');
      }
    }
  }
  processEmptyEmpList() {
    // removing already upload employees
    if (this.alreadyImportedEmployees.length == 1) {
      if (_.findIndex(this.employeesData, { reportingTo: "Head of Company" }) > -1) {
        this.headOfCompanyFound = true;
      } else {
        this.headOfCompanyFound = false
      }
      if (this.employeeEmailIds.indexOf(this.userInfo.email) > -1) {




        this.employeeEmailFound = true;


      } else {


        this.employeeEmailFound = false;
      }
    }
  }
  resetAllSections() {
    this.uploadEmpSectionsArr.map((section) => {
      this.uploadEmpSections[section]["show"] = false;
    });
    this.xlsxUploadedFileName = ''
  }

  counter(i: number) {
    return new Array(i);
  }

  renameKeys(obj, key, newKey) {
    if (_.includes(_.keys(obj), key)) {
      obj[newKey] = _.clone(obj[key]);
      delete obj[key];
    }
    obj["rmempid"] = obj["reportingTo"];
    return obj;
  };
  uploadFiles() {


    let removedItems = _.remove(this.employeesData, obj => this.shouldRemoveEmpIds.indexOf(obj.empid) > -1);
    let msg = "Are you sure would like to proceed without uploading '" + removedItems.length + "' of employee records that have errors?";

    this.employeesData.map((empData) => {

      empData['dob'] = this.dateFormatChange(empData['dob']);
      empData['doj'] = this.dateFormatChange(empData['doj']);




      empData['empid'] = empData['empid'].toString().trim();
      empData['name'] = empData['name'].toString().trim();
      empData['gender'] = empData['gender'].toString().trim();
      empData['grade'] = empData['grade'].toString().trim();
      empData['designation'] = empData['designation'].toString().trim();
      empData['unit'] = empData['unit'].toString().trim();
      empData['unithead'] = empData['unithead'].toString().trim();
      empData['location'] = empData['location'].toString().trim();
      empData['branch'] = empData['branch'].toString().trim();
      empData['reportingTo'] = empData['reportingTo'].toString().trim();
      empData['subunit'] = empData['subunit'].toString().trim();
      empData['subunit2'] = empData['subunit2'].toString().trim();
      empData['rmempid'] = empData['rmempid'].toString().trim();
      empData['mobile'] = empData.mobile.toString();
      empData['mobile'] = empData['mobile'].trim();

      console.log('date', empData['dob'], empData['doj'], empData);
    });



    let finalEmployeeList = []
    this.employeesData.forEach((each) => {
      console.log('empData', each.doj);

      let str = each.unit.toString();
      let arr = [];
      arr = str.split(',');
      let unitArray = []
      arr.map((e) => {
        unitArray.push(e.toString().trim())
      });
      console.log('realData', unitArray);

      each.modifyData = {
        empid: each.empid,
        rmempid: each.rmempid,
        name: each.name,
        gender: each.gender,
        mobile: each.mobile,
        email: each.email,
        dob: each.dob,
        doj: each.doj,
        grade: each.grade,
        designation: each.designation,
        unit: unitArray,
        unithead: each.unithead,
        subunit: each.subunit,
        subunit2: each.subunit2,
        location: each.location,
        branch: each.branch,
        reportingTo: each.reportingTo
      }
      finalEmployeeList.push(each.modifyData)
    })

    console.log('real world', finalEmployeeList);


    let params = { employees: finalEmployeeList };

    // let params = { employees: this.employeesData };
    if (this.alreadyImportedEmployees.length == 1) {
      params['isFirstUpload'] = true;
      this.isFirstUploadValidation = true;
    }
    if (removedItems.length > 0) {
      this._service.openConfirmDialog(msg, 'invalidError', false, () => {
        this.uploadInProgress = true;
        this.uploadServiceCall(params)
      });
    } else {
      this.uploadInProgress = true;
      this.uploadServiceCall(params); 
    }

  }
  uploadServiceCall(params) {

    this._service.importEmployees(params).subscribe(res => {
      if (res.success) {
        this.hideUploadProgress = false;
        if(this.isFirstUploadValidation == true){
          this._service.openConfirmDialog(res.msg, 'relogin', false, () => {
              localStorage.clear();
              this.router.navigate(["login"]);
          });
        }
        else{
          this._service.openConfirmDialog(res.msg, 'error', false, () => {
            this.router.navigate(['/winzard/orgWIN/organization']);
            // this.isProccedKey = false;
          });
        }
        
      }
      else {
        this._service.openConfirmDialog(res.msg, 'error', false, () => {
          this.uploadInProgress = false;
          this.resetAllSections();
          this.uploadEmpSections['uploadArea']['show'] = true;
        });
      }
    }, err => {


      if (err.error.data && err.status === 500) {
        this.uploadInProgress = false;
        this.resetAllSections();
        this.uploadEmpSections['backendErrorArea']['show'] = true;
        this.backendInvalidempList = err.error.data;
      }
      else if (err.error.data && err.status === 400) {
        this.uploadInProgress = false;
        this.resetAllSections();
        this.uploadEmpSections['backendErrorArea']['show'] = true;
        this.backendInvalidempList = err.error.data;
      }
      else {
        this.backendInvalidempList = []
        this._service.openConfirmDialog(err.error.msg, 'error');
      }

    });
  }
  downloadSampleFile() {
    window.open('../../../../assets/emp_template.xlsx');
  }
  ngAfterViewInit() {
    this.autoUploadSubscription = this.flow.events$.subscribe(event => {
      this.flow.flowJs.files = []
      if (event.type === 'filesSubmitted') {
        this.flow.upload();
        this.xlsxUploadedFileName = event.event[0][0].file['name'];
        console.log('Afterview', this.xlsxUploadedFileName);
        
        this.onFileChange(event.event[0][0].file);
      }
    });
  }
  goBack(areaName) {
   
    
    this.invalidEmpList = [];
    this.uniqueGrades = [];
    this.uniqueLocations = [];
    this.uniqueUnits = [];
    this.uploadingEmpIds = [];
    this.employeesData = [];
    this.alreadyImportedEmployees = [];
    this.employeeEmailIds = [];
    this.shouldRemoveEmpIds = [];
    this.invalidMandatoryEmpList = [];
    this.invalidEmpListWithReportingTo = [];
    this.uploadEmailIds = [];
    this.duplicateEmployee = [];
    this.backendInvalidempList = [];
    this.headOfCompanyFound = true;
    this.employeeEmailFound = true;
    this.duplicateHOCfound = false;
    this.duplicateEmailBoolean = false;
    this.InvalidReporting = false;
    this.isFirstUploadValidation = false;

    this.resetAllSections();
    this.uploadEmpSections[areaName]['show'] = true;
    console.log('areaName', areaName, this.employeesData);
  }
  //&& this.invalidEmpEmail.length >1 && this.invalidEmpEmail.length >0 && this.invalidEmpEmail.length ==1 && this.employeeEmailFound == true
  onNext(areaName) {

    console.log('Invalid', this.invalidEmpList);
    console.log('Mandatory', this.invalidMandatoryEmpList);
    console.log('Reporting', this.invalidEmpListWithReportingTo);

    if (this.employeeEmailFound == true && this.invalidEmpList.length == 0) {
      this.uploadFiles();
    } else {
      this.resetAllSections();
      this.uploadEmpSections[areaName]['show'] = true;
    }

  }
  ngOnDestroy() {
    this.autoUploadSubscription.unsubscribe();
  }

  logOut() {
    this._service.openConfirmDialog('Are you sure you want to logout?', 'logoutConfirmation', false, () => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });

  }
  reload(){
    console.log('done');
    // location.reload();
    this.ngAfterViewInit();
    
  }

}
