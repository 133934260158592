import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {

  private _addEmployeeMsg  = new Subject<string>();
  private _EmployeeMsg = new Subject<string>();


  employeeMsg$ = this._addEmployeeMsg.asObservable();
  employee1Msg$ = this._EmployeeMsg.asObservable();
  constructor() { 
  }
  sendMessage(message:string){
    this._addEmployeeMsg.next(message);
  }

  sendError(message:string){
    this._EmployeeMsg.next(message)
  }

}
