import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, retry } from "rxjs/operators";
import { throwError, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CategoryManagementService {
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  getCategoryList(body): Observable<any> {
    let path = this.baseUrl + "v3.0/category/getCategoryList";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  createNewCategoryValue(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/createNewCatValue";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getCategoryValuesList(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/getCategoryValuesList";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  deleteCategoryValue(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/deleteCatgValue";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  createNewCategory(body): Observable<any> {
    let path = this.baseUrl + "v3.0/category/createNewCategory";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  deleteCategory(body): Observable<any> {
    let path = this.baseUrl + "v3.0/category/deleteCategory";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getEmployeesOfCatgeory(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/getEmployeesOfCatgeory";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  addEmployeesOfCatgeoryValue(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/assocaiteEmpToCategory";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getEmployeesOfNotCatgeory(body): Observable<any> {
    let path = this.baseUrl + "v3.0/categoryValue/getEmployeesOfNotCatgeory";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
