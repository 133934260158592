import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FlowDirective, Transfer } from '@flowjs/ngx-flow';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import * as _ from "lodash";
import { AuthService } from '../../authentication/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HrService } from '../../hr/hr.service';
import { InteractionService } from '../../../app/shared/services/interaction.service';
import { async } from '@angular/core/testing';
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../../app/shared/components/confirm-dialog/confirm-dialog.component";
import { GoogleAnalyticsService } from "../../shared/services/google-analytics.service";
@Component({
  selector: 'app-survey-uploadusers',
  templateUrl: './survey-uploadusers.component.html',
  styleUrls: ['./survey-uploadusers.component.scss']
})
export class SurveyUploadusersComponent implements OnInit {

  @ViewChild('flow', { static: false }) flow: FlowDirective;
  public employeesData = [];
  public backEndEmpKeys = ["empid", "name", "gender", "mobile", "email", "dob", "doj", "grade", "designation", "unit", "location", "reportingTo", "rmempid"];
  public xlsxMandatoryEmpKeys = ["empid", "name", "gender", "mobile", "email", "dob", "doj"];
  public alphaNumericKeys = ["empid", "name", "reportingTo"];
  public originalXlOptionalKeys = ["GradeorLevel", "Designation", , "UnitorFunction", "Location ", "ReportingManager EmployeeCode"];
  public originalXlMandatorylKeys = [];
  public xlsxEmpKeys = [];
  public processedEmpObject = [];
  public empUniqueItems = [];
  public invalidEmpList = [];
  public invalidMandatoryEmpList = [];
  public invalidEmpListWithReportingTo = [];
  public invalidEmpEmail: any = [];
  public validEmpList = [];
  public alreadyImportedEmailId = [];
  public uploadEmailIds = []
  public alreadyImportedEmployees = [];
  public uploadingEmpIds = [];
  public xlsxUploadedFileName;
  public uploadInProgress: boolean = false;
  public headOfCompanyFound: boolean = true;
  public employeeEmailFound: boolean = true;
  public hideUploadProgress: boolean = true;
  public InvalidReporting: boolean = false;
  public isFirstUploadValidation: boolean = false;
  public isProccedKey: boolean = false;
  public showSpinner: boolean = true;
  public duplicateHOCfound: boolean = false;
  public duplicateEmployee = []
  public testArray = [];
  public employeeEmailIds = [];
  public shouldRemoveEmpIds = [];
  public allEmployees = [];
  public headOfCompanyEmployee = [];
  public duplicateHeadOfCompany = [];
  public allImportedEmpIds = [];
  public lastIndex: any;
  dateBith: boolean = false;
  duplicateEmailBoolean = false;
  public userInfo: any;
  public uploadEmpSections = {
    "uploadArea": { "show": true },
    "errorArea": { "show": false },
    "backendErrorArea": { "show": false },
    "uniqueItemsArea": { "show": false }
  };
  public backendInvalidempList = []
  public copyEmployeesData = [];
  public uniqueGrades = [];
  public uniqueLocations = [];
  public uniqueUnits = [];
  public maxUniqueItems: number;
  public uploadEmpSectionsArr = Object.keys(this.uploadEmpSections);
  public autoUploadSubscription: Subscription;
  public uploadEmpDataFromAddEmpDialog = JSON.parse(localStorage.getItem("employeeUploadFromAddEmpDialog"));
  public originalExcelData = [];
  public newlyUploadedEmployees = [];
  public renamedKeysEmployees = [];
  public currentLoginEmailId: any;
  @Input() from: string;
  @Output() uploadExcelEvent = new EventEmitter<string>();
  @Input() activeUserCreationTab: any;
  constructor(
    private _service: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private googleAnalyticsService:GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.googleAnalyticsService.sendCurrentActiveLink(window.location.href);
    this.userInfo = this._service.decodeToken();
    console.log('datta----->', this.userInfo);


    this.currentLoginEmailId = this.userInfo.email;
    if (!this.from) {
      localStorage.removeItem("employeeUploadFromAddEmpDialog");
    }

    if (this.uploadEmpDataFromAddEmpDialog) {
      this.employeesData = this.uploadEmpDataFromAddEmpDialog.empXlsxData;
      this.xlsxUploadedFileName = this.uploadEmpDataFromAddEmpDialog.fileName;
      console.log('log', this.xlsxUploadedFileName);

      this.originalXlMandatorylKeys = this.uploadEmpDataFromAddEmpDialog.originalXlMandatorylKeys;
      this.xlsxEmpKeys = Object.keys(this.employeesData[0]);
      this.resetAllSections();
      this.getValuesByTpes();
      this.uploadEmpSections['uniqueItemsArea']['show'] = true;
    }
  }
  ngOnChanges() {
    if (this.activeUserCreationTab == "userCreation") {
      this.xlsxUploadedFileName = "";
    }
  }



  onFileChange(ev) {
    this.employeesData = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev;
    this.uploadExcelEvent.emit();
    reader.onload = (event) => {
      let workBook = XLSX.read(reader.result, {
        type: 'binary', cellDates: true, cellNF: false,
        cellText: false
      });
      let jsonPagesArray = [];
      workBook.SheetNames.forEach(function (sheet) {
        jsonPagesArray.push(JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(workBook.Sheets[sheet], { defval: "" }))));
      });
      this.buildEmployeeData(jsonPagesArray[0]);
    }
    reader.readAsBinaryString(file);
  }
  buildEmployeeData(data) {
    let tempObj = Object.values(data);
    let xlKeys = Object.values(tempObj[0]);
    _.map(tempObj, (emp, index) => {
      if (index > 0) {
        let tempEmp = {};
        _.map(Object.values(emp), (em, emIndex) => {
          tempEmp[xlKeys[emIndex]] = em;
        });

        this.employeesData.push(tempEmp);
      }
    });
    this.xlsxEmpKeys = Object.keys(this.employeesData[0]);
    this.originalXlMandatorylKeys = _.remove(Object.keys(this.employeesData[0]), (key) => {
      console.log('keys', key);

      return _.indexOf(this.originalXlOptionalKeys, key) == -1;
    });

    console.log('orfirst', this.originalXlMandatorylKeys);


    this.originalExcelData = _.cloneDeep(this.employeesData);
    this.employeesData.map((empData) => {
      this.xlsxEmpKeys.map((ekey, eindex) => {
        this.renameKeys(empData, ekey, this.backEndEmpKeys[eindex]);
      });
    });
    this.renamedKeysEmployees = _.cloneDeep(this.employeesData);

    if (this.from) {
      let uploadInfoFromAddEmpDialog = {
        fileName: this.xlsxUploadedFileName,
        empXlsxData: this.employeesData,
        originalXlMandatorylKeys: this.originalXlMandatorylKeys
      };
      localStorage.setItem("employeeUploadFromAddEmpDialog", JSON.stringify(uploadInfoFromAddEmpDialog));
    } else {
      localStorage.removeItem("employeeUploadFromAddEmpDialog");
    }

  }

  validateUploadedFile(areaName) {
    this.resetAllSections();
    this.getValuesByTpes();
    this.uploadEmpSections[areaName]['show'] = true;
  }

  arrayFilter(key, arr) {
    let str = key.toString();
    let splitArr = [];
    splitArr = str.split(',');
    splitArr.map((val) => {
      val = val.toString().trim();
      arr.push(val);
      arr.sort();
    })
    return arr;
  }
  //grade unit location
  trimArrayKeysConvert(arr) {
    arr.forEach((empData) => {
      Object.keys(empData).forEach(key => {
        let gradeArray = [];
        let unitArray = [];
        let locationArray = []
        if (key) {
          empData[key] = empData[key].toString().trim();
        }

        if (key == 'grade') {
          gradeArray = this.arrayFilter(empData[key], gradeArray);
          empData[key] = gradeArray;
        }
        if (key == 'unit') {
          unitArray = this.arrayFilter(empData[key], unitArray);
          empData[key] = unitArray;
        }
        if (key == 'location') {
          locationArray = this.arrayFilter(empData[key], locationArray);
          empData[key] = locationArray;
        }
        empData['category'] = [{ name: 'grade', value: empData['grade'] }, { name: 'unit', value: empData['unit'] }, { name: 'location', value: empData['location'] }]

      });
      delete empData['grade'];
      delete empData['unit'];
      delete empData['location'];
    })
  }
  trimArrayKey(arr) {
    arr.forEach((empData) => {
      Object.keys(empData).forEach(key => {
        let gradeArray = [];
        let unitArray = [];
        let locationArray = []
        if (key) {
          empData[key] = empData[key].toString().trim();
        }

      });
    })
  }
  getValuesByTpes() {
    this.showSpinner = true;
    this._service.getValuesByTypeUsers({ filter: false, type: "empids" }).subscribe(res => {
      if (res.statusCode == 200) {
        if (res.data.length > 0) {
          this.showSpinner = false;
          this.alreadyImportedEmployees = _.map(res.data, "empId");
          this.newlyUploadedEmployees = _.cloneDeep(this.employeesData);
          this.trimArrayKeysConvert(this.employeesData);
          console.log('data', this.employeesData);

          if (this.newlyUploadedEmployees.length > 0) {
            this.trimArrayKey(this.newlyUploadedEmployees);
            this.processEmployeeId()
            this.processEmployeeObject();
            this.processEmptyEmpList();
          }

        }
      }
    });

  }
  processEmployeeId() {
    this.newlyUploadedEmployees.map((empData) => {

      if (this.uploadingEmpIds.indexOf(empData['empid']) == -1) {
        this.uploadingEmpIds.push((empData['empid']).toString());
      }
    })


  }
  dateFormatChange(key) {
    if (key.indexOf('T') > -1) {
      key = moment(key).add(2, 'hour').format("DD/MMMM/YYYY");
      key = moment(key).format("MM/DD/YYYY");
    }
    else {
      key = moment(key, 'DD-MM-YYYY').add(2, 'hour').format("DD/MM/YYYY");
      key = moment(key, 'DD/MM/YYYY').format('MM-DD-YYYY');
      key = moment(key, 'MM-DD-YYYY').format('MM/DD/YYYY');

    }


    return key;
  }

  uniqueFilter(key, arr) {
    if (key && arr.indexOf(key) == -1) {
      arr.push(key);
      arr.sort();
    }
    return arr;
  }

  uniqueArrayFilter(key, arr) {
    let str = key.toString();
    let splitArr = [];
    splitArr = str.split(',');
    splitArr.map((val) => {
      val = val.toString().trim();
      if (val && arr.indexOf(val) == -1) {
        arr.push(val);
        arr.sort();
      }
    })
    return arr;
  }

  processEmployeeObject() {


    this.newlyUploadedEmployees.map((empData, index) => {
      if (empData['dob'] && empData['dob'].length > 0) {
        empData['dob'] = this.dateFormatChange(empData['dob']);
      }
      if (empData['doj'] && empData['doj'].length > 0) {
        empData['doj'] = this.dateFormatChange(empData['doj']);
      }


      Object.keys(empData).forEach(key => {
        if (key) {
          empData[key] = empData[key].toString().trim();
          if (this.xlsxMandatoryEmpKeys.indexOf(key) > -1 && empData[key] == "" && _.findIndex(this.invalidEmpList, { "empid": empData["empid"] }) == -1) {
            this.invalidEmpList.push(empData);
            this.removeItems(empData)
            console.log('excel upload', empData);

            if (empData['dob'] != "" && empData['doj'] != "" && empData['empid'] != "" && empData['name'] != "" && empData['gender'] != "") {
              if (empData['mobile'].length == 0 && empData['email'].length > 0) {
                _.remove(this.invalidEmpList, { empid: empData['empid'] });
                _.remove(this.shouldRemoveEmpIds, obj => obj == empData['empid'])
              }
              else if (empData['mobile'].length > 0 && empData['email'].length == 0) {
                _.remove(this.invalidEmpList, { empid: empData['empid'] });
                _.remove(this.shouldRemoveEmpIds, obj => obj == empData['empid'])
              }
            }

          }

        }
      });
      // if (empData['doj'] == "" || empData['empid'] == "" || empData['name'] =="" || empData['gender']=="") {
      //   this.removeItems(empData)
      // }
      this.uniqueGrades = this.uniqueArrayFilter(empData.grade, this.uniqueGrades);
      this.uniqueLocations = this.uniqueArrayFilter(empData.location, this.uniqueLocations);
      this.uniqueUnits = this.uniqueArrayFilter(empData.unit, this.uniqueUnits);


      this.maxUniqueItems = Math.max(...[this.uniqueGrades.length, this.uniqueLocations.length, this.uniqueUnits.length]);
      if (this.employeeEmailIds.indexOf(empData.email) == -1) {
        this.employeeEmailIds.push(empData.email);
      }
      if (index == this.newlyUploadedEmployees.length - 1) {
        this.lastIndex = index;
      }
      this.validateFields(empData);


    });

  }
  removeItems(empData) {
    if (this.shouldRemoveEmpIds.indexOf(empData['empid']) == -1) {
      this.shouldRemoveEmpIds.push(empData['empid']);

    }
  }

  validateFields(empData) {
    let copyEmp = _.cloneDeep(empData);

    if (empData) {
      console.log('empId', empData['empid']);
      console.log('removeIds', this.shouldRemoveEmpIds);



      let index = _.findIndex(this.invalidEmpList, { empid: copyEmp.empid });

      Object.keys(copyEmp).forEach(key => {
        if (this.alphaNumericKeys.indexOf(key) > -1) {

          if (!(/^([a-zA-Z0-9 _-]+)$/.test(empData[key])) && empData[key].length > 0) {

            if (index > -1) {
              this.invalidEmpList[index][key] = { valid: false, value: empData[key] };
              console.log('final2-------->', this.invalidEmpList);
              this.removeItems(empData)
            } else {
              copyEmp[key] = { valid: false, value: copyEmp[key] };
              this.invalidEmpList.push(copyEmp);
              console.log('final3-------->', this.invalidEmpList);
              this.removeItems(empData)
            }
          }
        }
      });
      if (copyEmp.mobile && /^([0-9 _-]+)$/.test(copyEmp.mobile) && copyEmp.mobile.toString().length > 5) {

        console.log('valid mobile', copyEmp.mobile);

      }
      else {
        if (index > -1 && copyEmp.mobile) {

          this.invalidEmpList[index]["mobile"] = { valid: false, value: copyEmp.mobile };
          this.removeItems(empData)
        }
        else {
          if (copyEmp['mobile'].length > 0) {
            copyEmp["mobile"] = { valid: false, value: copyEmp.mobile };
            this.invalidEmpList.push(copyEmp);
            this.removeItems(empData)
          }

        }
      }
      if (copyEmp.email && !(/(.+)@(.+){2,}\.(.+){2,}/.test(copyEmp.email))) {
        if (index > -1) {
          this.invalidEmpList[index]["email"] = { valid: false, value: copyEmp.email };
          this.removeItems(empData)

        } else {
          copyEmp["email"] = { valid: false, value: copyEmp.email };
          this.invalidEmpList.push(copyEmp);
          this.removeItems(empData);

        }


      }

      if (copyEmp.doj) {
        if (copyEmp['doj'] == 'Invalid date') {
          copyEmp["doj"] = { valid: false, value: copyEmp.doj };
          this.invalidEmpList.push(copyEmp);
          this.removeItems(empData);
          this.invalidEmpList = _.uniqBy(this.invalidEmpList, 'empid');
        }
      }
      if (copyEmp.dob) {
        if (copyEmp['dob'] == 'Invalid date') {
          copyEmp["dob"] = { valid: false, value: copyEmp.dob };
          this.invalidEmpList.push(copyEmp);
          this.removeItems(empData);
          this.invalidEmpList = _.uniqBy(this.invalidEmpList, 'empid');
        }
      }



      if (this.lastIndex == this.newlyUploadedEmployees.length - 1) {
        this.invalidEmpList = _.uniqBy(this.invalidEmpList, 'empid');
      }
    }
  }

  processEmptyEmpList() {
    if (this.userInfo && this.userInfo.firstImport == false) {
      if (this.employeeEmailIds.indexOf(this.userInfo.email) > -1) {
        this.employeeEmailFound = true;
      } else {
        this.employeeEmailFound = false;
      }
    }
    else{
      this.employeeEmailFound = true;
    }

  }
  resetAllSections() {
    this.uploadEmpSectionsArr.map((section) => {
      this.uploadEmpSections[section]["show"] = false;
    });
    this.xlsxUploadedFileName = ''
  }

  counter(i: number) {
    return new Array(i);
  }

  renameKeys(obj, key, newKey) {
    if (_.includes(_.keys(obj), key)) {
      obj[newKey] = _.clone(obj[key]);
      delete obj[key];
    }
    obj["rmempid"] = obj["reportingTo"];
    return obj;
  };
  uploadFiles() {
    let removedItems = _.remove(this.employeesData, obj => this.shouldRemoveEmpIds.indexOf(obj.empid) > -1);
    let msg = "Are you sure would like to proceed without uploading '" + removedItems.length + "' of employee records that have errors?";

    let params = { employees: this.employeesData };
    console.log('finalData', params);

    if(this.userInfo && this.userInfo.firstImport == false){
      this.isFirstUploadValidation = true;
    }
    if (removedItems.length > 0) {
      this._service.openConfirmDialog(msg, 'invalidError', false, () => {
        this.uploadInProgress = true;
        this.uploadServiceCall(params)
      });
    } else {
      this.uploadInProgress = true;
      this.uploadServiceCall(params);
    }

  }
  uploadServiceCall(params) {

    this._service.importUsers(params).subscribe(res => {
      if (res.success) {
        this.hideUploadProgress = false;
        if (this.isFirstUploadValidation == true) {
          this._service.openConfirmDialog(res.msg, 'relogin', false, () => {
            localStorage.clear();
            this.router.navigate(["login"]);
          });
        }
        else {
          this._service.openConfirmDialog(res.msg, 'error', false, () => {
            this.router.navigate(['/winzard/orgWIN/survey-organization']);
            // this.isProccedKey = false;
          });
        }

      }
      else {
        this._service.openConfirmDialog(res.msg, 'error', false, () => {
          this.uploadInProgress = false;
          this.resetAllSections();
          this.uploadEmpSections['uploadArea']['show'] = true;
        });
      }
    }, err => {


      if (err.error.data && err.status === 500) {
        this.uploadInProgress = false;
        this.resetAllSections();
        this.uploadEmpSections['backendErrorArea']['show'] = true;
        this.backendInvalidempList = err.error.data;
      }
      else if (err.error.data && err.status === 400) {
        this.uploadInProgress = false;
        this.resetAllSections();
        this.uploadEmpSections['backendErrorArea']['show'] = true;
        this.backendInvalidempList = err.error.data;
      }
      else {
        this.backendInvalidempList = []
        this._service.openConfirmDialog(err.error.msg, 'error');

      }

    });
  }
  downloadSampleFile() {
    window.open('../../../../assets/emp_template.xlsx');
  }
  ngAfterViewInit() {
    this.autoUploadSubscription = this.flow.events$.subscribe(event => {
      this.flow.flowJs.files = []
      if (event.type === 'filesSubmitted') {
        this.flow.upload();
        this.xlsxUploadedFileName = event.event[0][0].file['name'];
        console.log('Afterview', this.xlsxUploadedFileName);

        this.onFileChange(event.event[0][0].file);
      }
    });
  }
  goBack(areaName) {


    this.invalidEmpList = [];
    this.uniqueGrades = [];
    this.uniqueLocations = [];
    this.uniqueUnits = [];
    this.uploadingEmpIds = [];
    this.employeesData = [];
    this.alreadyImportedEmployees = [];
    this.employeeEmailIds = [];
    this.shouldRemoveEmpIds = [];
    this.invalidMandatoryEmpList = [];
    this.invalidEmpListWithReportingTo = [];
    this.uploadEmailIds = [];
    this.duplicateEmployee = [];
    this.backendInvalidempList = [];
    this.headOfCompanyFound = true;
    this.employeeEmailFound = true;
    this.duplicateHOCfound = false;
    this.duplicateEmailBoolean = false;
    this.InvalidReporting = false;
    this.isFirstUploadValidation = false;

    this.resetAllSections();
    this.uploadEmpSections[areaName]['show'] = true;
  }

  onNext(areaName) {
    if (this.employeeEmailFound == true && this.invalidEmpList.length == 0) {
      this.uploadFiles();
    } else {
      this.resetAllSections();
      this.uploadEmpSections[areaName]['show'] = true;
    }

  }
  ngOnDestroy() {
    this.autoUploadSubscription.unsubscribe();
  }

  logOut() {
    this._service.openConfirmDialog('Are you sure you want to logout?', 'logoutConfirmation', false, () => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });

  }
  reload() {
    this.ngAfterViewInit();

  }


}
