import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { MaterialModule } from "./material/material.module";
import { RouterModule } from "@angular/router";
import { TasksComponent } from "./components/tasks/tasks.component";
import { CustomDropdownComponent } from "./components/custom-dropdown/custom-dropdown.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "./date.adapter";
import { AlphhaNumericDirective } from "./validators/alphha-numeric.directive";
import { MaxLengthValidatorDirective } from "./validators/maxLength-validator.directive";
import { NumbersOnlyDirective } from "./validators/numbers-only.directive";
import { RatingDialogComponent } from "./components/rating-dialog/rating-dialog.component";
import { GoalManagementComponent } from "./components/goal-management/goal-management.component";
import { EmailOtpPageComponent } from "../authentication/email-otp-page/email-otp-page.component";
import { NgOtpInputModule } from "ng-otp-input";
import { UploadUsersComponent } from "../onboard/upload-users/upload-users.component";
import {SurveyUploadusersComponent} from "../onboard/survey-uploadusers/survey-uploadusers.component"
import { NgxFlowModule, FlowInjectionToken } from "@flowjs/ngx-flow";
import Flow from "@flowjs/flow.js";
import { AgGridModule } from "ag-grid-angular";
import { OnlyalphabetsDirective } from './validators/onlyalphabets.directive';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ChartsModule } from 'ng2-charts';
import { NoRightClickDirective } from './validators/no-right-click.directive';
import { annoucmentsPipe } from "./validators/annoucements.pipe";

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    TasksComponent,
    CustomDropdownComponent,
    ConfirmDialogComponent,
    AlphhaNumericDirective,
    MaxLengthValidatorDirective,
    NumbersOnlyDirective,
    RatingDialogComponent,
    GoalManagementComponent,
    EmailOtpPageComponent,
    UploadUsersComponent,
    SurveyUploadusersComponent,
    OnlyalphabetsDirective,
    ConfirmationComponent,
    NoRightClickDirective,
    annoucmentsPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    NgOtpInputModule,
    AgGridModule.withComponents([]),
    NgxFlowModule,
    ChartsModule,
    
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbModule,
    HeaderComponent,
    SidebarComponent,
    TasksComponent,
    CustomDropdownComponent,
    AlphhaNumericDirective,
    MaxLengthValidatorDirective,
    NumbersOnlyDirective,
    OnlyalphabetsDirective,
    GoalManagementComponent,
    EmailOtpPageComponent,
    UploadUsersComponent,
    SurveyUploadusersComponent,
    AgGridModule,
    NoRightClickDirective,
    annoucmentsPipe
  ],
  entryComponents: [ConfirmDialogComponent, RatingDialogComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    {
      provide: FlowInjectionToken,
      useValue: Flow,
    },ConfirmationComponent
  ],
})
export class SharedModule {}
