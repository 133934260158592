import { Directive, Output, EventEmitter, Input, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
@Directive({
  selector: '[appMaxLengthValidator]'
})
export class MaxLengthValidatorDirective {

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input() maxEntry: number;
  @Input() formControl: FormControl;
  constructor(private _el: ElementRef) { }
  @HostListener('input', ['$event'])
  onInputChange(event) {
    const intialValue = this._el.nativeElement.value; 
    let filterdVal = intialValue.replace(/[^a-zA-Z0-9 ]/g, '')
    if (this.maxEntry && filterdVal.length > this.maxEntry) {
      filterdVal = filterdVal.substr(0, this.maxEntry);
    }
if (this.formControl) {
      this.formControl.setValue(filterdVal);
    } else {
      this._el.nativeElement.value = filterdVal;
    }

    this.ngModelChange.emit(filterdVal);
    if (filterdVal !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
