import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../authentication/auth.service';
import { SharedService } from "../../../shared/shared.service";
import { InteractionService } from '../../../../app/shared/services/interaction.service';
import { CommonModuleServiceService } from "../../../shared/services/v3/common-module-service.service";
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],


})
export class HeaderComponent implements OnInit {
  showFiler:boolean = false;
  public userInfo: any;
  public usersInfo: any;
  personalInfoData: any;
  openStatus:boolean = false;
  myWinActive: boolean = false;
  myTeamActive: boolean = false;
  myInsightActive: boolean = false;
  moduleList:any;
  goalAchiverExist: boolean;
  talentManagerExist: boolean ;
  peopleAssessmentExist: boolean ;
  workplaceSafety:boolean;
  workplaceEngagement:boolean;
  constructor(
    private router: Router,
    private authServoce: AuthService,
    private shared: SharedService,
    private _interactionService: InteractionService,
    private common:CommonModuleServiceService,
    private observer: BreakpointObserver
  ) { }
  ngOnInit() {
    this.usersInfo = this.authServoce.decodeToken();
    //  this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //  this.usersInfo = JSON.parse(localStorage.getItem("userInfo")).data.user;
    this.getPersonelInfo();
    this.ModuleExist();

    this._interactionService.employeeMsg$.subscribe(message => {
      console.log('msg-------->', message);
      this.getPersonelInfo();

    })
    this.myWinActive = false;
    this.myTeamActive = false;
    this.myInsightActive = false;
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
      console.log('small');
        this.showFiler = true;
      } else {
        console.log('big');
        this.showFiler = false;
      }
    });
  }

  async ModuleExist() { 
    this.moduleList = await this.common.getModulesList();

    if (this.usersInfo.isSurvey == false) {
      if(this.moduleList && this.moduleList.moduleData && this.moduleList.moduleData.length >0){
        this.goalAchiverExist = this.moduleList.moduleData.findIndex(x => x.moduleId.moduleId === 'GA') > -1;
        console.log('==================>', this.goalAchiverExist);
        
        this.talentManagerExist = this.moduleList.moduleData.findIndex(x => x.moduleId.moduleId === 'TM') > -1;
        this.peopleAssessmentExist = this.moduleList.moduleData.findIndex(x => x.moduleId.moduleId === 'PA') > -1;
      }
      
    }
    else{
        this.workplaceSafety = this.moduleList.moduleData.findIndex(x => x.moduleId.moduleId === 'WS') > -1;
        this.workplaceEngagement= this.moduleList.moduleData.findIndex(x => x.moduleId.moduleId === 'WE') > -1;
    }


  }

  logOut() {
    this.authServoce.openConfirmDialog('Are you sure you want to logout?', 'logoutConfirmation', false, () => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });

  }

  getName(name) {
    if (name) {
      const list = String(name).split(" ");
      let str = "";
      list.map((x) => (str = str + x.substring(0, 1)));
      return str;
    }
    return "";
  }

  getPersonelInfo() {
    if (this.usersInfo.isSurvey == false) {
      this.shared
        .getPersonelInfo({ empId: this.usersInfo._id })
        .subscribe((res) => {
          console.log('data', res);
          if (res && res.success) {
            this.personalInfoData = res.data[0];


          }
        });
      this.shared
        .getNextFincalYearDetailsList()
        .subscribe((res) => {
          console.log('data', res);
          if (res && res.success) {
            console.log('NextFY');

          }
        });
    }
    else {
      this.shared
        .getPersonelInfoSurvey({ empId: this.usersInfo._id })
        .subscribe((res) => {
          console.log('data', res);
          if (res && res.success) {
            this.personalInfoData = res.data[0];


          }
        });
    }




  }
  myWinTab() {

    this.myWinActive = true;
    this.myTeamActive = false;
    this.myInsightActive = false;
  }
  myTeamTab() {
    this.myWinActive = false;
    this.myTeamActive = true;
    this.myInsightActive = false
  }
  myInsightTab() {
    this.myWinActive = false;
    this.myTeamActive = false;
    this.myInsightActive = true;
  }
  setting() {
    this.myWinActive = false;
    this.myTeamActive = false;
    this.myInsightActive = false;
  }
  Org() {
    this.myWinActive = false;
    this.myTeamActive = false;
    this.myInsightActive = false;
  }
  menuOpened(){
    console.log('Menu is open');
    this.openStatus = true;
  }
  menuClosed(){
    console.log('menu is closed');
    this.openStatus = false;
  }
}
