import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data.autoClose != ''){
        setTimeout(() => {
          this.closeDialog();
        }, 3000);
      }
     }

  closeDialog(): void {
    this.dialogRef.close(true);
  }
  closeDl(): void {
    this.dialogRef.close(false);
  }
  ngOnInit() {
    console.log(this.data);
  }
  onConfirm(){
    this.dialogRef.close(true);
  }
}
