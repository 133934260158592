import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InteractionService } from '../../app/shared/services/interaction.service';
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../app/shared/components/confirm-dialog/confirm-dialog.component";

const APP: any = JSON.parse(localStorage.getItem('userInfo'));
@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  private token;
  constructor(public authService: AuthService, private router: Router, private _interactionService: InteractionService,private dialog: MatDialog) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with basic auth credentials if available
    // if (localStorage.getItem('userInfo')){
    //   const info = JSON.parse(localStorage.getItem('userInfo'));
    //   request = request.clone({ setHeaders: { 'Authorization': `Bearer ${info.data.token}` } });
    //   return next.handle(request);
    // } 
    // return next.handle(request);

    // const info = JSON.parse(localStorage.getItem('userInfo'));
    const token = localStorage.getItem('token');

    
    if (token) {
      // req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${info.data.token}`) });
      req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
    }
    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        console.log(event, 'HttpResponse');
      }
      return event;
    }), catchError((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.authService.openConfirmDialog(err.error.msg, 'error')
      } else if (err.status === 500) {
        console.log(err);

        // if (err.error.isProceed == true) {
        //   console.log('else if block');
          
        // }
        // else{
          
        // }
        this.authService.openConfirmDialog(err.error.msg, 'error')
        
          
        


        // code - 3 --> failure
        // code -- 5 --> true
        //role -- 1 - hr
      }
      else if (err.status === 400) {
        if (err.error.startDate) {
          let date = new Date(err.error.startDate).toLocaleDateString();
          this.authService.openConfirmDialog(err.error.msg + '' + date, 'error')
        }
        else if (err.error.isProceed == true) {
          console.log('else if block');
          this.authService.openConfirmDialog(err.error.msg, 'userCreationFormDialog', false, () => {
            this._interactionService.sendError('Change profile')

          });
        }
        else {
          this.authService.openConfirmDialog(err.error.msg, 'error')
        }

      }
      throw err;
    }));
  }

  // async isProced(err){
  //   const dialog =  await this.dialog.open(ConfirmDialogComponent, {
  //     width: "500px",
  //     panelClass: "confirm-dialog",
  //     autoFocus: false,
  //     disableClose: true,
  //     data: {
  //       msg: err,
  //       type: 'userCreationFormDialog',
  //       autoClose: false
  //     },
  //   }).afterClosed().toPromise();

  //   console.log(dialog);

  //   if(dialog == true){
  //     console.log('dialog');
  //     this._interactionService.sendMessage('Change profile')
  //   }
  //   else{
  //     console.log('done');
  //     this._interactionService.sendError('Sending error')
  //   }
    

  // }
}
