import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AuthService } from "src/app/authentication/auth.service";

@Component({
  selector: "app-rating-dialog",
  templateUrl: "./rating-dialog.component.html",
  styleUrls: ["./rating-dialog.component.scss"],
})
export class RatingDialogComponent implements OnInit {
  ratings: Array<any> = [1, 2, 3, 4, 5];
  selectedValue = null;
  constructor(
    public dialogRef: MatDialogRef<RatingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.selectedValue = this.data.value;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  onRatingClick(ev) {
    this.selectedValue = ev;
  }

  addRating() {
    this.dialogRef.close({ success: true, value: this.selectedValue });
  }
}
