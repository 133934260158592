import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, retry } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InsightsService {
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getValueTypes(body): Observable<any> {
    let path = this.baseUrl + "getValuesByType";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getSupervisorList(body): Observable<any> {
    let path = this.baseUrl + "v2.0/employees/getSupervisorsList";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getValueTypepeopleassesment(body, accessby: string): Observable<any> {
    let path = this.baseUrl + "v2.0/people-assessment-reviews/filters/" + accessby;
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getValueTypeTalent(body, accessby: string): Observable<any> {
    let path = this.baseUrl + "talent/filters/" + accessby;
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getUnitEmployeeList(): Observable<any> {

    let path = this.baseUrl + 'v2.0/employees/getUnitEmployeesList';
    return this.http.get<any>(path)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFincalYearCurrentPast(): Observable<any> {
    let path = this.baseUrl + 'getCurrentPastFinancialYears';
    var response = this.http.post<any>(path, '')
    return response;
  }
  getFincalYearDetailsList(): Observable<any> {
    let path = this.baseUrl + 'getFincalYearDetails';
    var response = this.http.post<any>(path, '')
    return response;
  }
  getInsightsDetailsByfinancialYear(accessby: string, body): Observable<any> {

    let path = this.baseUrl + 'v2.0/people-assessments/start-dates-for-insights';
    var response = this.http.post<any>(path, body);
    return response;
  }
  getInsightsDetailsByAccess(accessby: string, body): Observable<any> {

    let path = this.baseUrl + 'v2.0/people-assessment-reviews/insights/' + accessby;
    var response = this.http.post<any>(path, body);
    return response;
  }
  getStartDatesByFinacialYear(body): Observable<any> {
    let path = this.baseUrl + 'v2.0/people-assessments/start-dates-by-finacial-year'
    var response = this.http.post<any>(path, body);
    return response;
  }
  getInsightsData(body): Observable<any> {
    let path = this.baseUrl + "talent/getInsightsTalentReview";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getInsightsAnsData(body): Observable<any> {
    let path = this.baseUrl + "talent/getInsightsTalentReviewAnswer";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }



  getTalentDates(): Observable<any> {
    let path = this.baseUrl + "talent/getTalentSurveyStartDates";
    return this.http.post<any>(path, "").pipe(catchError(this.handleError));
  }

  saveInsights(params): Observable<any> {
    let path = this.baseUrl + "talent/getUpdateTalentInsights";
    return this.http.post<any>(path, params).pipe(catchError(this.handleError));
  }

  getGoalInsightsData(body): Observable<any> {
    let path = this.baseUrl + "goal/getGoalInsights";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  getFincalYearDetails(): Observable<any> {
    let path = this.baseUrl + "getFincalYearDetails";
    return this.http.post<any>(path, "").pipe(catchError(this.handleError));
  }
  getUnitsForInsights(): Observable<any> {
    let path = this.baseUrl + "goal/getUnitsForInsights";
    return this.http.post<any>(path, "").pipe(catchError(this.handleError));
  }

  getSurveyStartDates(type:String): Observable<any> {

    let path = this.baseUrl + 'v3.0/surveyInsights/getSurveyStartDates/'+type;
    return this.http.get<any>(path).pipe(
        catchError(this.handleError)
      );
  }
  getSupervisorsList(): Observable<any> {

    let path = this.baseUrl + 'v3.0/users/getSupervisorsList';
    return this.http.post<any>(path,"").pipe(
        catchError(this.handleError)
      );
  }
  filtersSafetySurvey(body,accessby: string): Observable<any> {
    let path = this.baseUrl + "v3.0/surveyInsights/filters/"+ accessby;
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }

  getSafetyInsights(body): Observable<any> {
    let path = this.baseUrl + "v3.0/surveyInsights/fetchInsights";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  exportPdf(body): Observable<any> {
    let path = this.baseUrl + "v3.0/surveyInsights/exportPdf";
    return this.http.post<any>(path, body).pipe(catchError(this.handleError));
  }
  checkInsightAccess(): Observable<any> {

    let path = this.baseUrl + 'v3.0/surveyInsights/checkInsightAccess';
    return this.http.get<any>(path).pipe(
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
