import { Pipe, PipeTransform } from '@angular/core';

    

@Pipe({

  name: 'content'

})

export class annoucmentsPipe implements PipeTransform {

     

  transform(value: string): string {

      return value.replace(/\n/g, '<br/>');

 }

     

}