import { Directive,ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

@Directive({
  selector: '[appOnlyalphabets]'
})
export class OnlyalphabetsDirective {

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input() maxEntry: number;
  @Input() allowSpace: string;
  @Input() formControl: FormControl;

  constructor(private _el: ElementRef) { }
  @HostListener('input', ['$event'])
  onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    let filterdVal;


  

    // ^[^\s]+(\s+[^\s]+)*
    
    if (this.allowSpace === 'true') {
      filterdVal = initalValue.replace(/[^a-zA-Z ]/g, '');
    } else {
      filterdVal = initalValue.replace(/[^a-zA-Z ]/g, '');
    }

    if (this.maxEntry && filterdVal.length > this.maxEntry) {
      filterdVal = filterdVal.substr(0, this.maxEntry);
    }
     if (this.formControl) {
      this.formControl.setValue(filterdVal);
    } else {
      this._el.nativeElement.value = filterdVal;
    }
    this.ngModelChange.emit(filterdVal);
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
