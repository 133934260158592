import { Injectable } from '@angular/core';
declare let gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {

    gtag('event', eventAction, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventValue: eventValue
    });
    gtag('config', 'G-JLX35KC8TF');
  }

  public sendCurrentActiveLink(routeLink) {
    // gtag('set', 'page',routeLink);
    // gtag('send', 'pageview');
    gtag('set', {
      'page_title': routeLink,
    });
    gtag('config', 'G-JLX35KC8TF');
  }

}
